/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {FormEvent, useCallback, useEffect, useState} from 'react';

import {
  Loader,
  SearchField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  View
} from '@aws-amplify/ui-react';
import {DataStore, Predicates, SortDirection} from 'aws-amplify';
import Grid from '../../../components/common/Grid';
import Typeahead from '../../../components/common/Typeahead';

import {CampaignReports as CampaignReportsModel, Campaigns as Campaign, Reports as Report} from '../../../models';
import {TableContainer} from "./styles";
import useDebounce from "../../../hooks/useDebounce";
import Spinner from "../../../components/common/Spinner";

interface ReportMap extends Report {
  checked: boolean;
}

interface CampaingReportMap extends Campaign {
  reportMaps: ReportMap[];
}

function CampaignReports() {
  const [campaignReportMaps, setCampaignReportMaps] = useState<CampaingReportMap[]>([]);
  const [reports, setReports] = useState<Report[]>([]);
  const [reportName, setReportName] = useState<string>('');
  const [campaignName, setCampaignName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getCampaignReport = useCallback(async (campaignId: string, reportId: string) => {
    const [campaignReport] = await DataStore.query(CampaignReportsModel, c => c.and(innerC => [
      innerC.campaignReportsCampaignId.eq(campaignId),
      innerC.campaignReportsReportId.eq(reportId)
    ]));

    return campaignReport;
  }, []);

  const generateCampaignReportMaps = useCallback(async (selectedCampaigns: Campaign[], selectedReports: Report[]) => {
    if (!selectedCampaigns || !selectedReports) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return Promise.all(selectedCampaigns.map(async campaignItem => {
      const reportMaps = await Promise.all(selectedReports.map(async reportItem => {
        const campaignReport = await getCampaignReport(campaignItem.id, reportItem.id);

        return {
          ...reportItem,
          checked: campaignReport?.Checked || false
        };
      }));

      return {
        ...campaignItem,
        reportMaps
      } as CampaingReportMap;
    }));
  }, []);

  const loadResources = useDebounce(async ({campaign = "", report = ""} = {}) => {
    setIsLoading(true);

    try {
      const selectedReports = await DataStore.query(Report, criteria => criteria.Name.contains(report), {
        sort: condition => condition.Name(SortDirection.ASCENDING)
      });

      setReports(selectedReports);

      const selectedCampaigns = await DataStore.query(Campaign, criteria => criteria.or(innerCriteria => [
        innerCriteria.CampaignName.contains(campaign),
        innerCriteria.CampaignID.contains(campaign)
      ]), {
        sort: condition => condition.CampaignName(SortDirection.ASCENDING)
      });

      setCampaignReportMaps(await generateCampaignReportMaps(selectedCampaigns, selectedReports) || []);
    } catch(e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    loadResources();
  }, []);

  const onCampaignChange = useCallback(async (event: FormEvent<HTMLElement>) => {
    const {value} = event.currentTarget as HTMLInputElement;
    setCampaignName(value);
    await loadResources({campaign: value, report: reportName});
  }, [reportName]);

  const onReportChange = useCallback(async (event: FormEvent<HTMLElement>) => {
    const {value} = event.currentTarget as HTMLInputElement;
    setReportName(value);
    await loadResources({report: value, campaign: campaignName});
  }, [campaignName]);

  const onClickCheckbox = useCallback(async (campaignReportMap: CampaingReportMap, reportMap: ReportMap) => {
    const campaignReport = await getCampaignReport(campaignReportMap.id, reportMap.id);

    // eslint-disable-next-line no-param-reassign
    reportMap.checked = !reportMap.checked;

    if (campaignReport) {
      await DataStore.save(
        CampaignReportsModel.copyOf(campaignReport, updated => {
          // eslint-disable-next-line no-param-reassign
          updated.Checked = reportMap.checked;
        })
      );
    } else {
      await DataStore.save(
        new CampaignReportsModel({
          campaignReportsCampaignId: campaignReportMap.id,
          campaignReportsReportId: reportMap.id,
          Checked: reportMap.checked
        })
      );
    }

    const updatedCampaignReportMaps = campaignReportMaps.map(campaignReportMapItem => {
      if (campaignReportMapItem.id === campaignReportMap.id) {
        return {
          ...campaignReportMapItem,
          reportMaps: campaignReportMapItem.reportMaps.map(reportMapItem => {
            if (reportMapItem.id === reportMap.id) {
              return reportMap;
            }

            return reportMapItem;
          })
        };
      }
      return campaignReportMapItem;
    });

    setCampaignReportMaps(updatedCampaignReportMaps);
  }, [campaignReportMaps, getCampaignReport]);

  return (
    <Grid>
      <View columnSpan={6}>
        <h3>Campaigns</h3>
      </View>

      <View columnSpan={6}>
        <h3>Reports</h3>
      </View>

      <View columnSpan={6}>
        <SearchField
          type="text"
          placeholder="Filter by campaigns"
          hasSearchButton={false}
          hasSearchIcon
          value={campaignName}
          onChange={onCampaignChange}
          label=""
        />
      </View>

      <View columnSpan={6}>
        <SearchField
          type="text"
          placeholder="Filter by reports"
          hasSearchButton={false}
          hasSearchIcon
          value={reportName}
          onChange={onReportChange}
          label=""
        />
      </View>

      <TableContainer columnSpan={12}>
        {isLoading ?
          <Spinner style={{ margin: 'auto'}} />
          :
          <Table size="small" variation="bordered" highlightOnHover>
            <TableHead>
              <TableRow>
                <TableCell as="th"/>
                {reports.map((item) => (
                  <TableCell key={item.id} as="th">
                    {item.Name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {campaignReportMaps.map((campaignReportMap) => (
                <TableRow key={campaignReportMap.id}>
                  <TableCell>
                    <p>{campaignReportMap.CampaignID} - {campaignReportMap.CampaignName}</p>
                  </TableCell>

                  {campaignReportMap.reportMaps.map((reportMap) => (
                    <TableCell key={reportMap.id}>
                      <label style={{
                        width: '100%',
                        height: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }} htmlFor={`reportMap-${campaignReportMap.CampaignID}-${reportMap.id}`}>
                        <input type="checkbox" name={`reportMap-${campaignReportMap.CampaignID}-${reportMap.id}`}
                               id={`reportMap-${campaignReportMap.CampaignID}-${reportMap.id}`}
                               checked={reportMap.checked}
                               onChange={() => onClickCheckbox(campaignReportMap, reportMap)}/>
                      </label>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      </TableContainer>
    </Grid>
  );
}

export default CampaignReports;
