import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from '@aws-amplify/ui-react';
import { DataStore } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { Campaigns as Campaign } from '../../../../models';
import useIsOwner from '../../../../hooks/useIsOwner';

interface CampaignsListProps {
  campaigns: Campaign[];
  onDeleteCampaign: (campaign?: Campaign) => any;
}

function CampaignsList({ campaigns, onDeleteCampaign }: CampaignsListProps) {
  const isOwner = useIsOwner();
  return (
    <Table size="small" variation="bordered" highlightOnHover>
      <TableHead>
        <TableRow>
          <TableCell as="th">Campaign Name</TableCell>
          <TableCell as="th">Campaign UUID</TableCell>
          {isOwner && <TableCell as="th">Actions</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {campaigns.map((campaign) => (
          <TableRow key={campaign.id}>
            <TableCell>{campaign.CampaignName}</TableCell>
            <TableCell>{campaign.CampaignUUID}</TableCell>
            {isOwner && (
              <TableCell>
                <Button
                  size="small"
                  onClick={async () => {
                    await DataStore.delete(campaign);
                    onDeleteCampaign(campaign);
                  }}
                >
                  <FontAwesomeIcon icon={faEraser} />
                  <span>Remove</span>
                </Button>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default CampaignsList;
