import { API, graphqlOperation } from 'aws-amplify';
import { useCallback } from 'react';
import useDebounce from './useDebounce';

const useDebounceAPI = (query: string, callback: Function = () => {}, waitMillis = 300) => {
  let promise: Promise<any>;

  return useCallback(
    useDebounce(async (variables = {}) => {
      if (promise) {
        API.cancel(promise);
      }

      try {
        promise = API.graphql(graphqlOperation(query, variables)) as Promise<any>;

        if (callback) {
          callback(promise);
        }
      } catch (e) {
        console.error(e);
      }
    }, waitMillis),
    [query, callback, waitMillis]
  );
};

export default useDebounceAPI;
