import React from 'react';

import './styles.css';

export default function Spinner({ style }: any) {
  return (
    <div style={style} className="spinner-container">
      <div className="spinner-loader" />
    </div>
  );
}
