import React from 'react';

import { Grid as AmplifyGrid, useTheme } from '@aws-amplify/ui-react';

export default function Grid({ children }: { children: React.ReactNode }) {
  const { tokens } = useTheme();
  return (
    <AmplifyGrid gap={tokens.space.small} templateColumns="repeat(12, minmax(0,1fr))">
      {children}
    </AmplifyGrid>
  );
}
