// import './CheckUrls.css';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Campaigns from './campaigns';
import ReportStatus from './status';
import CampaignReports from './campaignReports';

function CheckUrls() {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1>Reports</h1>
      </div>

      <Switch>
        <Route exact path="/reports">
          <Redirect to="/reports/status" />
        </Route>
        <Route path="/reports/status" component={ReportStatus} />
        <Route path="/reports/campaigns" component={Campaigns} />
        <Route path="/reports/campaignReports" component={CampaignReports} />
      </Switch>
    </div>
  );
}

export default CheckUrls;
