/* eslint-disable import/prefer-default-export */

export const listCheckItems = /* GraphQL */ `
  query ByCheckId(
    $checkItemsCheckId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCheckId(
      checkItemsCheckId: $checkItemsCheckId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        checkItemsCheckId
        Url
        RerunTaskUrl
        HttpResultCode
        BodyLength
        LastModified
        StartAt
        Status
        FinishAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        checkItemsCampaignId
        checkItemsReportId
        Report {
          id
          Name
        }
        Campaign {
          id
          CampaignID
          CampaignName
        }
      }
    }
  }
`;

export const listChecks = /* GraphQL */  `
  query ChecksByType(
    $Type: CheckType!
    $Date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChecksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checksByType(
      Type: $Type
      Date: $Date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Type
        Date
      }
      nextToken
      startedAt
    }
  }
`;
