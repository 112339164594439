import './CreateIncident.css';

import React, { useState, useEffect } from 'react';

import { Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

import ContentEditable from 'react-contenteditable';

import { Link } from 'react-router-dom';
import Logo from './exclamation-mark-svgrepo-com.svg';
import Logox from './loading.gif';

const initialState: {
  loadState: string;
  incidentName: string;
  incidentBody: string;
  incidentStatus: string;
  componentNameToStatus: { [key: string]: string };
} = {
  loadState: '',
  incidentName: '',
  incidentBody: 'We are currently investigating this issue.',
  incidentStatus: 'investigating',
  componentNameToStatus: {}
};

interface Page {
  id: string;
  name: string;
}

interface Incident {
  shortlink: string;
  updated_at: Date;
  id: string;
  name: string;
  body: string;
  status: string;
  page: Page;
  page_id: string;
  componentNameToStatus: { [key: string]: string };
  metadata: {
    id: {
      value: string;
    };
  };
}

interface Component {
  id: string;
  name: string;
  status: string;
  page_id: string;
}

interface IncidentTemplate {
  shortlink: string | undefined;
  page_id: string;
  id: string;
  name: string;
  body: string;
  status: string;
  title: string;
  update_status: string;
  components: Component[];
}
interface CachedData {
  pageIdToPage: { [key: string]: Page };
  pageIdToIncidentIds: { [key: string]: string[] };
  incidentIdToIncident: { [key: string]: Incident };
  incidentIdToPageId: { [key: string]: string };
  internalIdToIncidentIds: { [key: string]: string[] };
  foreignIncidentIds: string[];
  pageIdToComponentIds: { [key: string]: string[] };
  componentIdToComponent: { [key: string]: Component };
  componentIdToPageId: { [key: string]: string };
  componentNameToPageIdComponentId: { [key: string]: { [key: string]: string } };
  componentNamesNotInAllPages: { [key: string]: string };
  incidentTemplateNameToIncidentTemplate: { [key: string]: IncidentTemplate };
}

const initialCachedData: CachedData = {
  pageIdToPage: {},
  pageIdToIncidentIds: {},
  incidentIdToIncident: {},
  incidentIdToPageId: {},
  internalIdToIncidentIds: {},
  foreignIncidentIds: [],
  pageIdToComponentIds: {},
  componentIdToComponent: {},
  componentIdToPageId: {},
  componentNameToPageIdComponentId: {},
  componentNamesNotInAllPages: {},
  incidentTemplateNameToIncidentTemplate: {}
};

/**
 *
 * Available data mappings:
 *
 * cachedData['pageIdToPage']
 *
 * cachedData['pageIdToIncidentIds']
 * cachedData['incidentIdToIncident']
 * cachedData['incidentIdToPageId']
 * cachedData['internalIdToIncidentIds']
 *
 * cachedData['pageIdToComponentIds']
 * cachedData['componentIdToComponent']
 * cachedData['componentIdToPageId']
 * cachedData['componentNameToPageIdComponentId']
 */
function CreateIncident() {
  const [cachedData, setCachedData] = useState<CachedData>(initialCachedData);
  const [shouldLoadData, setShouldLoadData] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [componentCheckboxSelected, setComponentCheckboxSelected] = useState<{ [key: string]: boolean }>({});
  const [formState, setFormState] = useState(initialState);
  const [shouldShowSelectAllComponents, setShouldShowSelectAllComponents] = useState(false);
  const [shouldShowSelectAllPages, setShouldShowSelectAllPages] = useState(false);
  const [pageCheckboxSelected, setPageCheckboxSelected] = useState<{ [key: string]: boolean }>({});
  const [createResult, setCreateResult] = useState<Incident[]>([]);
  const delayBetweenApiCalls: number = localStorage.getItem('delayBetweenCalls') ? parseFloat(localStorage.getItem('delayBetweenCalls') || '1000') : 1000;

  function setInput(key: string, value: string | { [key: string]: string }) {
    setFormState((fState) => ({ ...fState, [key]: value }));
  }

  function setComponentStatus(componentName: string, status: string) {
    if (!('componentNameToStatus' in formState)) {
      (formState as any).componentNameToStatus = {};
    }
    formState.componentNameToStatus[componentName] = status;
    setInput('componentNameToStatus', formState.componentNameToStatus);
  }

  const apiUrl = 'https://incidents.test.tutuka.cloud/api';

  async function loadData() {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    // Load Pages
    fetch(`${apiUrl}/v1/pages`, {
      method: 'GET',
      headers: {
        authorization: token,
        Accept: 'application/json'
      }
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(`${response.status}`);
      })
      .then((json) => {
        const pageIdToPage: { [key: string]: Page } = {};
        json.forEach((page: Page) => {
          pageIdToPage[page.id] = page;
        });
        cachedData.pageIdToPage = pageIdToPage;
        return pageIdToPage;
      })
      .then((pageIdToPage) => {
        // Load Unresolved incidents
        const pageIdToIncidentIds: { [key: string]: string[] } = {};
        const incidentIdToIncident: { [key: string]: Incident } = {};
        const incidentIdToPageId: { [key: string]: string } = {};
        const internalIdToIncidentIds: { [key: string]: string[] } = {};
        const foreignIncidentIds: string[] = [];

        setInput('loadState', '[Phase 1] Fetching unresolved incidents...');

        const requests = Object.keys(pageIdToPage).map((pageId, index) =>
          new Promise((resolve) => {
            setTimeout(resolve, index * delayBetweenApiCalls);
          })
            .then(() =>
              fetch(`${apiUrl}/v1/pages/${pageId}/incidents/unresolved?page=1`, {
                method: 'GET',
                headers: {
                  authorization: token,
                  Accept: 'application/json'
                }
              })
            )
            .then((res) => {
              setInput('loadState', `[Phase 1] Incidents fetched for the page "${pageIdToPage[pageId].name}" [${index + 1}/${Object.keys(pageIdToPage).length}]`);
              return res.json();
            })
            .then((incidents) => {
              incidents.forEach((incident: Incident) => {
                // console.log(incident);
                if (!(pageId in pageIdToIncidentIds)) {
                  pageIdToIncidentIds[pageId] = [];
                }
                pageIdToIncidentIds[pageId].push(incident.id);
                incidentIdToIncident[incident.id] = incident;
                incidentIdToPageId[incident.id] = pageId;

                if (incident.metadata?.id?.value) {
                  if (!(incident.metadata.id.value in internalIdToIncidentIds)) {
                    internalIdToIncidentIds[incident.metadata.id.value] = [];
                  }
                  internalIdToIncidentIds[incident.metadata.id.value].push(incident.id);
                } else {
                  // This incident was not created by the tool.
                  foreignIncidentIds.push(incident.id);
                }
              });
            })
            .then(() => {
              setInput('loadState', `[Phase 1] Incidents loaded for the page "${pageIdToPage[pageId].name}" [${index + 1}/${Object.keys(pageIdToPage).length}]`);
            })
        );
        return Promise.all(requests).then(() => {
          cachedData.pageIdToIncidentIds = pageIdToIncidentIds;
          cachedData.incidentIdToIncident = incidentIdToIncident;
          cachedData.incidentIdToPageId = incidentIdToPageId;
          cachedData.internalIdToIncidentIds = internalIdToIncidentIds;
          cachedData.foreignIncidentIds = foreignIncidentIds;
        });
      })
      .then(() => {
        setCachedData(cachedData);
      })
      .then(() => {
        const pageIdToComponentIds: { [key: string]: string[] } = {};
        const componentIdToPageId: { [key: string]: string } = {};
        const componentIdToComponent: { [key: string]: Component } = {};
        const componentNameToPageIdComponentId: { [key: string]: { [key: string]: string } } = {};
        const componentNameToStatus: { [key: string]: string } = {};
        const componentNamesNotInAllPages: { [key: string]: string } = {};

        setInput('loadState', '[Phase 2] Loading components...');

        const requests = Object.keys(cachedData.pageIdToPage).map((pageId, index) =>
          new Promise((resolve) => {
            setTimeout(resolve, index * delayBetweenApiCalls);
          })
            .then(() =>
              fetch(`${apiUrl}/v1/pages/${pageId}/components?page=1`, {
                method: 'GET',
                headers: {
                  authorization: token,
                  Accept: 'application/json'
                }
              })
            )
            .then((res) => res.json())
            .then((components) => {
              components.forEach((component: Component) => {
                if (!(component.page_id in pageIdToComponentIds)) {
                  pageIdToComponentIds[component.page_id] = [];
                }
                pageIdToComponentIds[component.page_id].push(component.id);
                componentIdToComponent[component.id] = component;
                componentIdToPageId[component.id] = component.page_id;

                if (!(component.name in componentNameToPageIdComponentId)) {
                  componentNameToPageIdComponentId[component.name] = {};
                }
                componentNameToPageIdComponentId[component.name][component.page_id] = component.id;

                componentNameToStatus[component.name] = 'operational';
              });
            })
            .then(() => {
              setInput('loadState', `[Phase 2] Loaded components for the page "${cachedData.pageIdToPage[pageId].name}" [${index + 1}/${Object.keys(cachedData.pageIdToPage).length}]`);
            })
        );

        return Promise.all(requests)
          .then(() => {
            Object.keys(componentNameToPageIdComponentId).forEach((componentName) => {
              if (Object.keys(cachedData.pageIdToPage).length !== Object.keys(componentNameToPageIdComponentId[componentName]).length) {
                componentNamesNotInAllPages[componentName] = componentName;
              }
            });
          })
          .then(() => {
            cachedData.pageIdToComponentIds = pageIdToComponentIds;
            cachedData.componentIdToComponent = componentIdToComponent;
            cachedData.componentIdToPageId = componentIdToPageId;
            cachedData.componentNameToPageIdComponentId = componentNameToPageIdComponentId;
            cachedData.componentNamesNotInAllPages = componentNamesNotInAllPages;
            setInput('componentNameToStatus', componentNameToStatus);

            /**
                console.log("wtf>");
                console.log(cachedData);
                console.log("<wtf")
                * */
          });
      })
      .then(() => {
        const incidentTemplateNameToIncidentTemplate: { [key: string]: IncidentTemplate } = {};

        setInput('loadState', '[Phase 3] Loading templates...');

        const requests = Object.keys(cachedData.pageIdToPage).map((pageId, index) =>
          new Promise((resolve) => {
            setTimeout(resolve, index * delayBetweenApiCalls);
          })
            .then(() =>
              fetch(`${apiUrl}/v1/pages/${pageId}/incident_templates?page=1`, {
                method: 'GET',
                headers: {
                  authorization: token,
                  Accept: 'application/json'
                }
              })
            )
            .then((res) => res.json())
            .then((templates) => {
              templates.forEach((template: IncidentTemplate) => {
                incidentTemplateNameToIncidentTemplate[template.name] = template;
              });
            })
            .then(() => {
              setInput('loadState', `[Phase 3] Loaded templates for the page "${cachedData.pageIdToPage[pageId].name}" [${index + 1}/${Object.keys(cachedData.pageIdToPage).length}]`);
            })
        );

        return Promise.all(requests).then(() => {
          cachedData.incidentTemplateNameToIncidentTemplate = incidentTemplateNameToIncidentTemplate;
        });
      })
      .then(() => {
        setInput('loadState', 'Done');

        setCachedData(cachedData);
        setDataLoading(false);
      });
  }

  const dumpData = () => {
    console.log(cachedData);
  };

  function toggleComponentCheckboxSelected(id: string) {
    setComponentCheckboxSelected({
      ...componentCheckboxSelected,
      [id]: id in componentCheckboxSelected ? !componentCheckboxSelected[id] : true
    });
    return componentCheckboxSelected[id];
  }

  function selectAllComponents() {
    const selection: { [key: string]: boolean } = {};
    Object.keys(cachedData.componentNameToPageIdComponentId).forEach((componentName) => {
      selection[componentName] = true;
    });
    setShouldShowSelectAllComponents(false);
    setComponentCheckboxSelected(selection);
  }

  function unselectAllComponents() {
    const selection: { [key: string]: boolean } = {};
    Object.keys(cachedData.componentNameToPageIdComponentId).forEach((componentName) => {
      selection[componentName] = false;
    });
    setShouldShowSelectAllComponents(true);
    setComponentCheckboxSelected(selection);
  }

  function togglePageCheckboxSelected(id: string) {
    setPageCheckboxSelected({
      ...pageCheckboxSelected,
      [id]: id in pageCheckboxSelected ? !pageCheckboxSelected[id] : true
    });
    return pageCheckboxSelected[id];
  }

  function selectAllPages() {
    const selection: { [key: string]: boolean } = {};
    Object.keys(cachedData.pageIdToPage).forEach((pageId) => {
      selection[pageId] = true;
    });
    setShouldShowSelectAllPages(false);
    setPageCheckboxSelected(selection);
  }

  function unselectAllPages() {
    const selection: { [key: string]: boolean } = {};
    Object.keys(cachedData.pageIdToPage).forEach((pageId) => {
      selection[pageId] = false;
    });
    setShouldShowSelectAllPages(true);
    setPageCheckboxSelected(selection);
  }

  async function createIncident() {
    if (formState.incidentName === '') {
      alert('Error: Incident name cannot be empty.');
      return;
    }

    if (formState.incidentStatus === '') {
      alert('Error: Incident status cannot be empty.');
      return;
    }

    if (formState.incidentBody === '') {
      alert('Error: Incident body cannot be empty');
      return;
    }

    if (typeof formState.componentNameToStatus === 'undefined') {
      alert('Error: component status is undefined');
      return;
    }

    setDataLoading(true);

    const internalId = `${Date.now()}${Math.ceil(10000 * Math.random())}`;

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    const affectedPageIds = Object.keys(cachedData.pageIdToPage).filter((pageId) => pageCheckboxSelected[pageId]);

    // console.log('affected page ids:');
    // console.log(affectedPageIds);

    // const affectedComponentIdToStatus = {}
    const affectedComponentNames = Object.keys(cachedData.componentNameToPageIdComponentId).filter((componentName) => componentCheckboxSelected[componentName]);

    // console.log('affected component names');
    // console.log(affectedComponentNames);

    const affectedPageIdToComponentIds: { [key: string]: { [key: string]: string } } = {};
    affectedComponentNames.forEach((componentName) => {
      Object.keys(cachedData.componentNameToPageIdComponentId[componentName]).forEach((pageId) => {
        // console.log('pageid: ' + pageId);

        if (affectedPageIds.includes(pageId)) {
          if (!(pageId in affectedPageIdToComponentIds)) {
            affectedPageIdToComponentIds[pageId] = {};
          }

          const componentIdForPage = cachedData.componentNameToPageIdComponentId[componentName][pageId];
          affectedPageIdToComponentIds[pageId][componentIdForPage] = formState.componentNameToStatus[componentName];
        } else {
          // console.log('not added:' + pageId + '-' + componentName);
        }
      });
    });

    // console.log('affected pageid to component ids:')
    // console.log(affectedPageIdToComponentIds);

    setInput('loadState', '[Phase 0] Creating incidents...');
    const requests = affectedPageIds.map((pageId) =>
      fetch(`${apiUrl}/v1/pages/${pageId}/incidents`, {
        method: 'POST',
        headers: {
          authorization: token,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          incident: {
            name: formState.incidentName,
            body: formState.incidentBody,
            status: formState.incidentStatus,
            components: affectedPageIdToComponentIds[pageId],
            component_ids: Object.keys(affectedPageIdToComponentIds[pageId]),
            metadata: {
              id: {
                value: internalId
              },
              email: {
                value: user.attributes.email
              }
            }
          }
        })
      }).then((res) => res.json())
    );

    Promise.all(requests).then((response) => {
      // console.log(response)
      setInput('loadState', '[Phase 0] Incidents creation is completed.');
      setCreateResult(response as unknown as Incident[]);
      setShouldLoadData(true);
    });
  }

  const applyTemplate = (event: any) => {
    if (event.target.value === '0') {
      return;
    }

    setInput('templateName', event.target.value);
    const template = cachedData.incidentTemplateNameToIncidentTemplate[event.target.value];
    setInput('incidentName', template.title);
    setInput('incidentBody', template.body);
    setInput('incidentStatus', template.update_status);

    const { componentNameToStatus } = formState;
    Object.keys(componentNameToStatus).forEach((componentName) => {
      componentNameToStatus[componentName] = 'operational';
    });
    template.components.forEach((component) => {
      componentNameToStatus[component.name] = component.status;
    });
    setInput('componentNameToStatus', componentNameToStatus);
  };

  function getStatusCard(internalId: string) {
    const { status } = cachedData.incidentIdToIncident[cachedData.internalIdToIncidentIds[internalId][0]];
    switch (status) {
      case 'investigating':
        return <span className="investigatingStatusCardStatus">{status}</span>;
      case 'identified':
        return <span className="identifiedStatusCardStatus">{status}</span>;
      case 'monitoring':
        return <span className="monitoringStatusCardStatus">{status}</span>;
      default:
        return <span className="resolvedStatusCardStatus">{status}</span>;
    }
  }

  useEffect(() => {
    if (shouldLoadData) {
      loadData();
      setShouldLoadData(false);
    }
  });

  return (
    <div>
      {dataLoading ? (
        <div
          style={{
            fontSize: '25px',
            width: '800px',
            margin: '0 auto',
            background: '#100',
            color: '#fff',
            zIndex: 20
          }}
        >
          {formState.loadState}
        </div>
      ) : (
        ''
      )}

      {dataLoading ? (
        <div style={{ zIndex: 20 }}>
          <img src={Logox} alt="loading..." className="loadingImage" />
          <div className="overlay" />
        </div>
      ) : (
        ''
      )}

      {/*
        <GifLoader
          loading={true}
          imageSrc="/loading.gif"
          overlayBackground="rgba(0,0,0,0.5)"
        />
      */}

      <Form>
        <Button onClick={dumpData} style={{ display: 'none' }}>
          Dump Data
        </Button>
      </Form>

      <h1>Ongoing incidents</h1>
      <div>
        {'internalIdToIncidentIds' in cachedData ? (
          Object.keys(cachedData.internalIdToIncidentIds)
            .sort(
              (firstInternalId, secondInternalId) =>
                new Date().valueOf() -
                new Date(cachedData.incidentIdToIncident[cachedData.internalIdToIncidentIds[firstInternalId][0]].updated_at).valueOf() -
                (new Date().valueOf() - new Date(cachedData.incidentIdToIncident[cachedData.internalIdToIncidentIds[secondInternalId][0]].updated_at).valueOf())
            )
            .map((internalId) => (
              <div key={internalId} className="iframe-replace">
                <div className="frame-wrapper">
                  {getStatusCard(internalId)}
                  <Logo style={{ marginLeft: '10px', marginTop: '10px' }} />
                  <div className="frame-content">
                    <span className="incident-title">
                      <p>{cachedData.incidentIdToIncident[cachedData.internalIdToIncidentIds[internalId][0]].name.substring(0, 64)}</p>
                    </span>

                    <div className="incident-item incident-context">
                      Last updated {Math.floor((new Date().valueOf() - new Date(cachedData.incidentIdToIncident[cachedData.internalIdToIncidentIds[internalId][0]].updated_at).valueOf()) / 1000 / 60)}{' '}
                      minutes ago
                    </div>

                    <div>
                      <label htmlFor="short-link"> View Latest Updates: </label>
                      <div className="incident-item view-link">
                        {cachedData.internalIdToIncidentIds[internalId].map((incidentId) => (
                          <label key={incidentId} style={{ marginLeft: '4px', display: 'block' }}>
                            <span>- Incident: </span>
                            <a id="short-link" href={cachedData.incidentIdToIncident[incidentId].shortlink} target="_blank" rel="noreferrer">
                              [{cachedData.incidentIdToIncident[incidentId].shortlink}]
                            </a>
                            <span style={{ marginLeft: '10px', marginRight: '10px' }}>on Page:</span>
                            <a href={`https://manage.statuspage.io/pages/${cachedData.incidentIdToPageId[incidentId]}`} target="_blank" rel="noreferrer">
                              {cachedData.pageIdToPage[cachedData.incidentIdToPageId[incidentId]].name}
                            </a>
                          </label>
                        ))}
                      </div>
                    </div>

                    <div className="updateButtonWrapperDiv">
                      <Link to={`/incidents/update/${internalId}`} className="btn btn-primary">
                        Update
                      </Link>
                      {/* <Button onClick={event => { loadIncident(internalId) }} variant="primary" id="createButton">
                                                Update
                                            </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <div>Loading</div>
        )}
      </div>

      <div>
        {'foreignIncidentIds' in cachedData ? (
          cachedData.foreignIncidentIds.map((incidentId) => (
            <div key={incidentId} className="iframe-replace">
              <div className="frame-wrapper">
                <Logo style={{ marginLeft: '10px', marginTop: '10px' }} />
                <div className="frame-content">
                  <span className="incident-title">
                    <p>{cachedData.incidentIdToIncident[incidentId].name.substring(0, 64)}</p>
                  </span>

                  <div className="incident-item incident-context">
                    Last updated {Math.floor((new Date().valueOf() - new Date(cachedData.incidentIdToIncident[incidentId].updated_at).valueOf()) / 1000 / 60)} minutes ago
                  </div>

                  <div>
                    <label htmlFor="short-link"> View Latest Updates: </label>
                    <div className="incident-item view-link">
                      <label style={{ marginLeft: '4px', display: 'block' }}>
                        <span>- Incident: </span>
                        <a id="short-link" href={cachedData.incidentIdToIncident[incidentId].shortlink} target="_blank" rel="noreferrer">
                          [{cachedData.incidentIdToIncident[incidentId].shortlink}]
                        </a>
                        <span style={{ marginLeft: '10px', marginRight: '10px' }}>on Page:</span>
                        <a href={`https://manage.statuspage.io/pages/${cachedData.incidentIdToPageId[incidentId]}`} target="_blank" rel="noreferrer">
                          {cachedData.pageIdToPage[cachedData.incidentIdToPageId[incidentId]].name}
                        </a>
                      </label>
                    </div>
                  </div>

                  <span>
                    <span className="warningIcon">
                      {' '}
                      Warning
                      <span className="warningMessage">This incident was not created by this tool. Manage it on the statuspage.io site.</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>Loading</div>
        )}
      </div>

      <div>
        {'internalIdToIncidentIds' in cachedData &&
        'foreignIncidentIds' in cachedData &&
        'pageIdToPage' in cachedData &&
        Object.keys(cachedData.internalIdToIncidentIds).length === 0 &&
        Object.keys(cachedData.foreignIncidentIds).length === 0 ? (
          <div style={{ background: 'green' }}>
            <div style={{ fontSize: '20px', color: 'white' }}>There are no ongoing incidents found in these pages:</div>
            <ul>
              {Object.keys(cachedData.pageIdToPage).map((pageId) => (
                <li key={pageId} style={{ fontSize: '20px', color: 'white' }}>
                  <a style={{ textDecoration: 'underline', color: 'white' }} href={`https://manage.statuspage.io/pages/${pageId}`} target="_blank" rel="noreferrer">
                    {cachedData.pageIdToPage[pageId].name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ''
        )}
      </div>

      {createResult.length > 0 ? (
        <div>
          <Button onClick={() => setCreateResult([])} variant="primary" id="hideButton">
            Hide the Results To Continue
          </Button>

          <h2>Create Result</h2>

          <div>
            <ul>
              {createResult.map((incident) => (
                <li key={incident.id}>
                  Incident Name: {incident.name} <br />
                  Incident Id: {incident.id} <br />
                  Page Id: {incident.page_id} <br />
                  Short Link:{' '}
                  <a href={incident.shortlink} target="_blank" rel="noreferrer">
                    {incident.shortlink}
                  </a>{' '}
                  <br />
                  Status: {incident.status} <br />
                  InternalId: {incident.metadata?.id?.value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <h1>Create incident</h1>
          <div>
            {'incidentTemplateNameToIncidentTemplate' in cachedData ? (
              <Form.Group>
                <Form.Label>Apply Template</Form.Label>
                <Form.Control as="select" onChange={(e) => applyTemplate(e)} type="" placeholder="">
                  <option value="0">Choose...</option>
                  {Object.keys(cachedData.incidentTemplateNameToIncidentTemplate).map((incidentTemplateName) => (
                    <option key={incidentTemplateName} value={incidentTemplateName}>
                      {incidentTemplateName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            ) : (
              <div>Loading</div>
            )}
          </div>
          <div>
            <Form.Group>
              <Form.Label>Incident Name</Form.Label>
              <Form.Control onChange={(event: any) => setInput('incidentName', event.target.value)} value={formState.incidentName} type="" placeholder="Incident Name" />
            </Form.Group>
          </div>
          <div>
            <Form.Group>
              <Form.Label>Incident Status</Form.Label>
              <Form.Control as="select" value={formState.incidentStatus || 'investigating'} onChange={(event) => setInput('incidentStatus', event.target.value)} type="" placeholder="">
                <option>investigating</option>
                <option>identified</option>
                <option>monitoring</option>
                <option>resolved</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div>
            <label htmlFor="incident-body">Message</label>
            <ContentEditable
              id="incident-body"
              className="editable"
              html={formState.incidentBody} // innerHTML of the editable div
              disabled={false} // use true to disable edition
              onChange={(event) => {
                setInput('incidentBody', event.target.value);
              }}
            />
          </div>

          <div>
            <h6>
              Components affected (
              <span>
                {shouldShowSelectAllComponents ? (
                  <a href="#!" onClick={selectAllComponents}>
                    Select all
                  </a>
                ) : (
                  <a href="#!" onClick={unselectAllComponents}>
                    Select none
                  </a>
                )}
              </span>
              )
            </h6>
            <hr />
            <div>
              {'componentNameToPageIdComponentId' in cachedData ? (
                Object.keys(cachedData.componentNameToPageIdComponentId).map((componentName) => (
                  <div className="checkbox" key={`checkbox_${componentName}`}>
                    {componentName in cachedData.componentNamesNotInAllPages ? (
                      <input type="checkbox" id={componentName} checked={false} disabled />
                    ) : (
                      <input
                        type="checkbox"
                        id={componentName}
                        onChange={() => toggleComponentCheckboxSelected(componentName)}
                        checked={componentName in componentCheckboxSelected ? componentCheckboxSelected[componentName] : toggleComponentCheckboxSelected(componentName)}
                      />
                    )}
                    <label className="itemName">{componentName}</label>
                    <span>
                      {componentName in cachedData.componentNamesNotInAllPages ? (
                        <span className="warningIcon">
                          {' '}
                          Warning
                          <span className="warningMessage">This component is not defined in all pages.</span>
                        </span>
                      ) : (
                        ''
                      )}
                    </span>

                    <div className="component_status">
                      {componentName in cachedData.componentNamesNotInAllPages ? (
                        <Form.Control
                          as="select"
                          value={formState.componentNameToStatus && formState.componentNameToStatus[componentName] ? formState.componentNameToStatus[componentName] : 'operational'}
                          onChange={(event) => setComponentStatus(componentName, event.target.value)}
                          type=""
                          placeholder=""
                          disabled
                        >
                          <option value="operational">Operational</option>
                          <option value="degraded_performance">Degraded performance</option>
                          <option value="partial_outage">Partial outage</option>
                          <option value="major_outage">Major outage</option>
                          <option value="under_maintenance">Under maintenance</option>
                        </Form.Control>
                      ) : (
                        <Form.Control
                          as="select"
                          value={formState.componentNameToStatus && formState.componentNameToStatus[componentName] ? formState.componentNameToStatus[componentName] : 'operational'}
                          onChange={(event) => setComponentStatus(componentName, event.target.value)}
                          type=""
                          placeholder=""
                        >
                          <option value="operational">Operational</option>
                          <option value="degraded_performance">Degraded performance</option>
                          <option value="partial_outage">Partial outage</option>
                          <option value="major_outage">Major outage</option>
                          <option value="under_maintenance">Under maintenance</option>
                        </Form.Control>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div>Loading</div>
              )}
            </div>
          </div>

          <div>
            <h6>
              Pages affected (
              <span>
                {shouldShowSelectAllPages ? (
                  <a href="#!" onClick={selectAllPages}>
                    Select all
                  </a>
                ) : (
                  <a href="#!" onClick={unselectAllPages}>
                    Select none
                  </a>
                )}
              </span>
              )
            </h6>
            <hr />
            <div>
              {'pageIdToPage' in cachedData ? (
                Object.keys(cachedData.pageIdToPage).map((pageId) => (
                  <div className="checkbox" key={`checkbox_${pageId}`}>
                    <input
                      type="checkbox"
                      id={pageId}
                      onChange={() => togglePageCheckboxSelected(pageId)}
                      checked={pageId in pageCheckboxSelected ? pageCheckboxSelected[pageId] : togglePageCheckboxSelected(pageId)}
                    />
                    <label className="itemName">
                      <a href={`https://manage.statuspage.io/pages/${pageId}`} target="_blank" rel="noreferrer">
                        {cachedData.pageIdToPage[pageId].name}
                      </a>
                    </label>
                  </div>
                ))
              ) : (
                <div>Loading</div>
              )}
            </div>
          </div>

          <Button
            onClick={() => {
              createIncident();
            }}
            variant="primary"
            id="createButton"
          >
            Create
          </Button>
        </div>
      )}
    </div>
  );
}

export default CreateIncident;
