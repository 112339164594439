import { Grid, Loader, View } from "@aws-amplify/ui-react";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { ClientDto } from "../../API";
import { getClientsByName } from "../../graphql/queries";
import useDebounceAPI from "../../hooks/useDebounceAPI";
import Typeahead from "../common/Typeahead";

interface Suggestion {
  label: string;
  value: ClientDto;
}

interface PickClientProps {
    onClientChanged: (client: ClientDto | null) => void,
    filteredClientIds?: number[],
    reset?: boolean
}


function PickClientByName(props: PickClientProps) {

    const {onClientChanged, filteredClientIds, reset} = props;

    const [clientName, setClientName] = useState<string>('');
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [loading, setLoading] = useState(false);



      useEffect(() => {
        if (reset) {
          setClientName('');
          setSuggestions([]);
        }
      }, [reset]);

    const onSelect = useCallback((suggestion: { label: string, value: ClientDto }) => {
        setClientName(suggestion.label);
  
        setSuggestions([]);

        onClientChanged(suggestion.value);
      },
      [setClientName, setSuggestions]);

      const onChange = useCallback(
        async (event: FormEvent<HTMLElement>) => {
          const element = event.currentTarget as HTMLInputElement;
          const {value} = element;
          setClientName(value);

          if (value === "") {
            onClientChanged(null);
          }
        },
        [setClientName]
      );

      const filterClients = useCallback((suggestionsReceived: Suggestion[]) => {

        const filtered = suggestionsReceived
        .filter((suggestion) =>
          !filteredClientIds?.some((clientId) => String(clientId) === suggestion.value.ClientID));

          setSuggestions(filtered);
      }, [filteredClientIds]);

      const onGetClientsByNameFinish = useCallback(
        async (promise: Promise<any>) => {
          const res = await promise;
          if (!res.data.getClientsByName) {
            return;
          }
    
          const fetchedClients = res.data.getClientsByName
            .map((item: ClientDto) =>
              ({label: `${item.ClientID} - ${item.ClientName}`, value: item})) as { label: string; value: ClientDto }[];

              if (filteredClientIds) {
                filterClients(fetchedClients);
              } else {
                setSuggestions(fetchedClients);
              }

              setLoading(false);
        }, [setSuggestions, filteredClientIds]
      )

      const debouncedGetClientsByName = useDebounceAPI(getClientsByName, onGetClientsByNameFinish);

      const fetchSuggestions = useCallback(async (value: string) => {
        if (!value) {
          return;
        }
        setLoading(true);
        debouncedGetClientsByName({name: value})
      }, [debouncedGetClientsByName])

    return (
        <Grid>
        <View columnSpan={6}>
          <span>Client</span>
          <Typeahead
            onChange={onChange}
            value={clientName}
            onFetch={fetchSuggestions}
            suggestions={suggestions}
            clearSuggestions={() => setSuggestions([])}
            placeholder="Search for Client..."
            onSelect={onSelect}
          />
          {loading ? <Loader className="loader" size="large"/> : null}
        </View>
  
      </Grid>
      );
}

export default PickClientByName;
