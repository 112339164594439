import { useCallback, useEffect, useState } from 'react';
import './styles.css';

function Datadog() {
  const [height, setHeight] = useState(600);
  const resize = useCallback(() => {
    const newHeight = window.innerHeight;
    setHeight(newHeight);
  }, [height]);
  useEffect(() => {
    window.addEventListener('resize', () => {
      resize();
    });
    resize();
    return () => {
      window.removeEventListener('resize', () => {
        resize();
      });
    };
  }, []);
  return (
    <div>
      <iframe
        title="Datadog"
        src="https://p.datadoghq.com/sb/ttzo04029hgcge46-a4f298e229468b8322408c73bce11047"
        width="98%"
        height={height}
        style={{ overflow: 'hidden', height, width: '98%', marginLeft: '1%' }}
      />
    </div>
  );
}

export default Datadog;
