import {SelectField} from '@aws-amplify/ui-react';
import React from 'react';
import moment from "moment";
import 'moment-timezone'

 function isBeforeEnd(timeValue: string, endLimit: string, lastValue: string) {
   const timeValueIsEarlier = moment(timeValue, 'h:mmA').diff(moment(endLimit, 'h:mmA')) < 0;
   const timeValueIsLaterThanLastValue = lastValue === undefined ? true : moment(lastValue, 'h:mmA').diff(moment(timeValue, 'h:mmA')) < 0;
   return timeValueIsEarlier && timeValueIsLaterThanLastValue;
}

type InputProps = {
  defaultValue: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  name: string;
  start: string;
  end: string;
  step: number;
}


export default function TimePicker({defaultValue, onChange, name, start, end, step}: InputProps) {

  let timeValue = start || "12:00AM";
  let lastValue: any;

  const endLimit = end || "11:59PM";
  const stp  = step || 1;

  const options = [];
  options.push(<option key={timeValue} value={timeValue}>{timeValue}</option>);
  while (isBeforeEnd(timeValue, endLimit, lastValue) ) {
    lastValue = timeValue;
    timeValue = moment(timeValue, 'h:mmA').add(stp, 'hour').format('h:mmA');
    options.push(<option key={timeValue} value={timeValue}>{timeValue}</option>)
  }
  return (
    <SelectField defaultValue={defaultValue} onChange={onChange} label={name}>
      {options}
    </SelectField>
  );
}
