import {API, DataStore, graphqlOperation, Hub} from 'aws-amplify';
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import Spinner from '../../components/common/Spinner';
import useLocalStorage, {LOCAL_STORAGE_KEYS} from '../../hooks/useLocalStorage';
import SpinnerContainer from './styles';
import {EnvironmentVariables} from "../../models";
import {getEnvironmentVariables} from "../../graphql/queries";

export const ConfigsContext = createContext({
  isDataStoreReady: false,
  environmentVariables: {} as EnvironmentVariables,
  userEmail: ""
});

export function ConfigsProvider({children, userEmail}: { children: React.ReactNode, userEmail: string}) {
  const [isDataStoreReady, setIsDataStoreReady] = useLocalStorage(LOCAL_STORAGE_KEYS.IS_DATASTORE_READY, false);
  const [environmentVariables, setEnvironmentVariables] = useLocalStorage<EnvironmentVariables>(LOCAL_STORAGE_KEYS.ENVIRONMENT_VARIABLES, {} as EnvironmentVariables);

  const [isEnvironmentVariableLoaded, setIsEnvironmentVariableLoaded] = useState(false);

  const contextParams = useMemo(() => ({
    isDataStoreReady,
    environmentVariables,
    userEmail
  }), [isDataStoreReady, environmentVariables, userEmail]);

  const initializeEnvironmentVariables = useCallback(async () => {
    if(isEnvironmentVariableLoaded) {
      return;
    }

    const {data: {getEnvironmentVariables: response}} = await (API.graphql(graphqlOperation(getEnvironmentVariables)) as Promise<any>);
    setEnvironmentVariables(response);
    setIsEnvironmentVariableLoaded(true);
  }, [environmentVariables, setEnvironmentVariables]);

  const initializeDataStore = useCallback(() => {
    if(isDataStoreReady) {
      return;
    }

    DataStore.start();

    Hub.listen('datastore', async (hubData) => {
      const {event} = hubData.payload;
      if (event === 'ready') {
        setIsDataStoreReady(true);
      }
    });
  }, [isDataStoreReady, setIsDataStoreReady]);

  useEffect(() => {
    initializeEnvironmentVariables();
  }, [initializeEnvironmentVariables]);

  useEffect(() => {
    initializeDataStore();
  }, [initializeDataStore])

  return (
    <ConfigsContext.Provider value={contextParams}>
      {isDataStoreReady ? (
        children
      ) : (
        <SpinnerContainer>
          <Spinner/>
        </SpinnerContainer>
      )}
    </ConfigsContext.Provider>
  );
}
