import './QuotaBalance.css';
import React, {useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';

import { DataStore, SortDirection, Predicates } from '@aws-amplify/datastore';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  View
} from '@aws-amplify/ui-react';
import { Quotas } from '../../models';
import { numberFormatter } from '../../utilities/TutukaFormatter';
import Grid from '../common/Grid';
import Spinner from '../common/Spinner';
import {ClientDto} from "../../API";
import TimePicker from "../common/Picker/TimePicker"
import PickClientByName from './PickClientByName';

const initialState = { clientId: 0, clientName: '', balanceLimit: 0, scheduleID: '' };

function WaitingTable({
  items,
  loading,
  queryItems
}: {
  items: Quotas[];
  loading: boolean;
  queryItems: () => void;
}) {
  if (loading) {
    return <Spinner />;
  }
  return (
    <Table variation="striped" highlightOnHover>
      <TableHead>
        <TableRow>
          <TableCell as="th">Schedule</TableCell>
          <TableCell as="th">Client ID</TableCell>
          <TableCell as="th">Client Name</TableCell>
          <TableCell as="th" className="currency">
            Balance Limit
          </TableCell>
          <TableCell as="th">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id}>
            <TableCell>{item.scheduleID}</TableCell>
            <TableCell>
              <code>{item.clientId}</code>
            </TableCell>
            <TableCell>
              {item.clientName}
            </TableCell>
            <TableCell className="currency">
              <code>{numberFormatter.format(item.balanceLimit as number)}</code>
            </TableCell>
            <TableCell>
              <Button
                type="button"
                size="small"
                onClick={async () => {
                  await DataStore.delete(item);
                  queryItems();
                }}
              >
                <FontAwesomeIcon icon={faEraser} />
                &nbsp;Remove
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function Items() {
  const [formState, setFormState] = useState(initialState);
  const [items, setItems] = useState<Quotas[]>([]);
  const [loading, setLoading] = useState(true);
  const [resetClientField, setResetClientField] = useState(false);

  const queryItems = useCallback(async () => {
    setItems(
      await DataStore.query(Quotas, Predicates.ALL, {
        sort: (item) => item.clientId(SortDirection.ASCENDING).balanceLimit(SortDirection.DESCENDING)
      })
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    queryItems();
  }, [queryItems]);

  function setInput(key: string, value: string) {
    setFormState(formStateOldValue =>  ({ ...formStateOldValue, [key]: value}));
  }

  const addQuota = useCallback(async () => {

    if (!formState.clientName || formState.clientId <= 0 || formState.balanceLimit <= 0 || !formState.scheduleID) {
      return;
    }
    setLoading(true);
    const checkQuotaItem = {
      clientId: Number(formState.clientId),
      clientName: formState.clientName,
      balanceLimit: Number(formState.balanceLimit),
      scheduleID: formState.scheduleID
    };
    await DataStore.save(new Quotas(checkQuotaItem));
    queryItems();

    setFormState(initialState);
    setResetClientField(true);
  }, [ setFormState, formState]);

  const onClientChanged = (client: ClientDto | null) => {
    setInput('clientId', String(client!.ClientID));
    setInput('clientName', String(client!.ClientName));
  }

  return (
    <Grid>
      <View columnSpan={12}>
        <h3>Register New Check</h3>
      </View>
      <View columnSpan={6}>
      <PickClientByName onClientChanged={onClientChanged} filteredClientIds={items.map(item => item.clientId!)} reset={resetClientField}/>
      </View>
      <View columnSpan={6}>
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInput('balanceLimit', event.target.value)}
          value={formState.balanceLimit}
          type="number"
          placeholder="50000"
          label="Balance Limit"
        />
      </View>
      <View columnSpan={12}>
        <TimePicker
          defaultValue="09:00AM"
          onChange={(event) => setInput('scheduleID', event.target.value)}
          name="Schedule (SAST)"
          start="12:00AM"
          end="11:00PM"
          step={1} />
      </View>

      <View columnSpan={12}>
        <Button onClick={addQuota} variation="primary">
          Register
        </Button>
      </View>
      <br/>
      <View columnSpan={12}>
        <h2>Defined Checks</h2>
      </View>
      <View columnSpan={12}>
        <WaitingTable items={items} loading={loading} queryItems={queryItems}/>
      </View>

    </Grid>
  );
}

export default Items;
