/* eslint-disable no-param-reassign */

import {API, graphqlOperation} from "aws-amplify";
import {CheckItems, CheckItemStatus, CheckType} from "../../../models";
import {listCheckItems} from "./queries";

export interface Campaign {
  campaignEntityUuid: string;
  campaignName: string;
  campaignId: string;
}

export interface ReportCheck {
  checked: boolean;
  checkDate: Date | string;
  reportUrl?: string;
  rerunTaskUrl?: string;
  generatedDate?: Date | string;
  reportSize?: number;
  status: CheckItemStatus;
  importantCheck?: boolean;
}

export interface Report {
  id: string;
  name: string;
  isNotChecked: () => boolean;
  isSuccessful: () => boolean;
  isFailed: () => boolean;
  isPartiallySuccessful: () => boolean;
  checks: ReportCheck[];
}

export interface CheckDto {
  id: string;
  Type: CheckType | keyof typeof CheckType;
  Date: number;
  CheckItems?: (CheckItems | null)[] | null;
  reportColumns: string[];
  campaigns: {
    checkId: string;
    campaignEntityUuid: string;
    campaignId: string;
    campaignName: string;
    reports: Report[];
  }[];
}

export function bytesToSize(bytes: number = 0) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Bytes';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
}

const getReportColumns = (check: any) => {
  const reportNames = check.CheckItems.flatMap((checkItem: any) => checkItem.Report).map((report: any) => report.Name);

  const reportColumns = Array.from(new Set(reportNames)) as string[];

  return reportColumns.sort((a, b) => a.localeCompare(b));
};

const getCampaigns = async (checkItems: CheckItems[]) => {
  const campaigns = [] as any[];

  checkItems.forEach(async (checkItem) => {
    const checkItemCampaing = await checkItem.Campaign;
    const campaign = campaigns.find(item => item.campaignId === checkItemCampaing?.CampaignID);

    if(!campaign) {
      campaigns.push({
        campaignEntityUuid: checkItemCampaing?.id,
        campaignId: checkItemCampaing?.CampaignID,
        campaignName: checkItemCampaing?.CampaignName,
        reports: []
      });
    }
  });

  return campaigns.sort((a, b) => a.campaignName.localeCompare(b.campaignName));
}

export async function fetchCheckItems(check: CheckDto): Promise<CheckDto> {
  const response = await API.graphql(graphqlOperation(listCheckItems, {limit: 9999999, checkItemsCheckId: check.id })) as any;

  check.CheckItems = response.data.byCheckId.items as CheckItems[];

  check.reportColumns = getReportColumns(check);

  check.campaigns = await getCampaigns(check.CheckItems as CheckItems[]);

  check.campaigns.forEach(async (campaign: any) => {
    check.reportColumns.forEach(async (reportColumn: string) => {
      const checkItems = check.CheckItems?.filter((item: any) => item.Report.Name === reportColumn
        && item.Campaign.id === campaign.campaignEntityUuid);

      if(!checkItems) {
        return;
      }

      const firstCheckItemReport = await checkItems[0]?.Report;

      const reportChecks = [] as ReportCheck[];

      const report = {
        id: firstCheckItemReport?.id,
        name: reportColumn,
        getSuccessCount: () => reportChecks.filter((reportCheck: ReportCheck) => reportCheck.status === CheckItemStatus.SUCCESS).length,
        getNotCheckedCount: () => reportChecks.filter((reportCheck: ReportCheck) => reportCheck.status === CheckItemStatus.NOT_CHECKED).length,
        isNotChecked: () => report.getNotCheckedCount() === report.checks.length,
        isSuccessful: () => report.getSuccessCount() === report.checks.length,
        isFailed: () => report.getSuccessCount() === 0,
        isPartiallySuccessful: () => report.getSuccessCount() > 0 && report.getSuccessCount() < report.checks.length,
        checks: reportChecks
      }

      campaign.reports.push(report);

      if(checkItems.length) {
        checkItems.forEach((checkItem: any) => {
          report.checks.push({
            checked: true,
            checkDate: checkItem.FinishAt && new Date(checkItem.FinishAt),
            reportUrl: checkItem.Url,
            rerunTaskUrl: checkItem.RerunTaskUrl,
            generatedDate: checkItem.LastModified && new Date(checkItem.LastModified),
            reportSize: checkItem.BodyLength,
            status: checkItem.Status
          });
        });
      }
    });
  });

  return check as CheckDto;
}
