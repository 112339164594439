import './App.css';
import { useEffect, useState } from 'react';

// local app elements
import { Amplify, Auth, Hub } from 'aws-amplify';
import { Authenticator, View, Image } from '@aws-amplify/ui-react';
import { BrowserRouter } from 'react-router-dom';

import FederatedSignIn from './components/sso/FederatedSignIn';
import Routes from './components/routes';
import { ConfigsProvider } from './context/configs';
import isAmplifyEnvWithSSO from './utilities/isAmplifyEnvWithSSO';
// AWS Amplify foundation

/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-enable import/extensions */
/* eslint-enable import/no-unresolved */

import '@aws-amplify/ui-react/styles.css'; // eslint-disable-line import/no-unresolved
// @ts-ignore
import awsconfig from './aws-exports'; // eslint-disable-line import/no-unresolved, import/extensions
import logo from './logo.png';

function getAppSynConfig () {
  return {
    aws_appsync_region: awsconfig.aws_appsync_region,
    aws_appsync_graphqlEndpoint: awsconfig.aws_appsync_graphqlEndpoint,
    aws_appsync_authenticationType: awsconfig.aws_appsync_authenticationType
  };
}

function getAmplifyConfigObject() {
  /*
    We retrieve the relevant Amplify config object, depending on whether
    we are on a main environment and we have enabled SSO or not.
  */

  if (!isAmplifyEnvWithSSO()) {
    return awsconfig;
  }

  const cognitoDomain = `cd-dashboard-main${process.env.REACT_APP_AWS_ENV_NAME}.auth.${process.env.REACT_APP_AWS_REGION}.amazoncognito.com`;
  const redirectURL = process.env.REACT_APP_SSO_REDIRECT_URL;

  const cognitoConfig = {
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_WEBCLIENT_ID,
      oauth: {
        domain: cognitoDomain,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: redirectURL,
        redirectSignOut: redirectURL,
        responseType: 'code'
      }
    }
  };

  return Object.assign(getAppSynConfig(), cognitoConfig);
}

const amplifyConfig = getAmplifyConfigObject();

Amplify.configure(amplifyConfig);

const components = {
  Header() {
    return (
      <View textAlign="center" padding={5}>
        <Image alt="Amplify logo" src={logo} height="50%" width="50%" />
      </View>
    );
  }
};

function AuthStateApp() {
  const providerName: string = process.env.REACT_APP_SSO_PROVIDER_NAME!;
  const isSSOAuth: boolean = isAmplifyEnvWithSSO();

  const [userEmail, setUserEmail] = useState<string | null>(null);

  function getCurrentAuthenticatedUserEmail() {
    return Auth.currentAuthenticatedUser().then((user) => user);
  }

  // Check if user is already logged in
  getCurrentAuthenticatedUserEmail()
    .then((user) => {
      setUserEmail(user.signInUserSession.idToken.payload.email);
    })
    .catch(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (_) => {
        setUserEmail(null);
      }
    );

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getCurrentAuthenticatedUserEmail().then((user) => {
            setUserEmail(user.signInUserSession.idToken.payload.email);
          });
          break;
        default:
          break;
      }
    });
  }, []);

  if (isSSOAuth) {
    return (
      <div>
        {userEmail ? (
          <ConfigsProvider userEmail={userEmail}>
            <BrowserRouter>
              <Routes userEmail={userEmail} />
            </BrowserRouter>
          </ConfigsProvider>
        ) : (
          <FederatedSignIn providerName={providerName} />
        )}
      </div>
    );
  }

  const formFields = {
    signUp: {
      given_name: {
        labelHidden: true,
        label: 'Given name:',
        placeholder: 'Enter your name',
        isRequired: true,
        order: 1
      },
      family_name: {
        labelHidden: true,
        label: 'Family name:',
        placeholder: 'Enter your family name',
        isRequired: true,
        order: 2
      }
    }
  };

  return (
    <Authenticator formFields={formFields} components={components}>
      {userEmail && (
        <ConfigsProvider userEmail={userEmail}>
          <BrowserRouter>
            <Routes userEmail={userEmail} />
          </BrowserRouter>
        </ConfigsProvider>
      )}
    </Authenticator>
  );
}

export default AuthStateApp;
