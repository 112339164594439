/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateQuotaRunsInput = {
  id?: string | null,
  quotaID: string,
  balanceLimit?: number | null,
  responseBody?: string | null,
  clientName?: string | null,
  clientId?: number | null,
  zendeskTicketId?: number | null,
  startAt?: number | null,
  finishAt?: number | null,
  lockedUntil?: number | null,
  _version?: number | null,
};

export type ModelQuotaRunsConditionInput = {
  quotaID?: ModelIDInput | null,
  balanceLimit?: ModelFloatInput | null,
  responseBody?: ModelStringInput | null,
  clientName?: ModelStringInput | null,
  clientId?: ModelIntInput | null,
  zendeskTicketId?: ModelIntInput | null,
  startAt?: ModelIntInput | null,
  finishAt?: ModelIntInput | null,
  lockedUntil?: ModelIntInput | null,
  and?: Array< ModelQuotaRunsConditionInput | null > | null,
  or?: Array< ModelQuotaRunsConditionInput | null > | null,
  not?: ModelQuotaRunsConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type QuotaRuns = {
  __typename: "QuotaRuns",
  id: string,
  quotaID: string,
  balanceLimit?: number | null,
  responseBody?: string | null,
  clientName?: string | null,
  clientId?: number | null,
  zendeskTicketId?: number | null,
  startAt?: number | null,
  finishAt?: number | null,
  lockedUntil?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateQuotaRunsInput = {
  id: string,
  quotaID?: string | null,
  balanceLimit?: number | null,
  responseBody?: string | null,
  clientName?: string | null,
  clientId?: number | null,
  zendeskTicketId?: number | null,
  startAt?: number | null,
  finishAt?: number | null,
  lockedUntil?: number | null,
  _version?: number | null,
};

export type DeleteQuotaRunsInput = {
  id: string,
  _version?: number | null,
};

export type CreateQuotasInput = {
  id?: string | null,
  scheduleID: string,
  clientId?: number | null,
  clientName?: string | null,
  balanceLimit?: number | null,
  _version?: number | null,
};

export type ModelQuotasConditionInput = {
  scheduleID?: ModelIDInput | null,
  clientId?: ModelIntInput | null,
  clientName?: ModelStringInput | null,
  balanceLimit?: ModelFloatInput | null,
  and?: Array< ModelQuotasConditionInput | null > | null,
  or?: Array< ModelQuotasConditionInput | null > | null,
  not?: ModelQuotasConditionInput | null,
};

export type Quotas = {
  __typename: "Quotas",
  id: string,
  scheduleID: string,
  clientId?: number | null,
  clientName?: string | null,
  balanceLimit?: number | null,
  withQuotaRuns?: ModelQuotaRunsConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelQuotaRunsConnection = {
  __typename: "ModelQuotaRunsConnection",
  items:  Array<QuotaRuns | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdateQuotasInput = {
  id: string,
  scheduleID?: string | null,
  clientId?: number | null,
  clientName?: string | null,
  balanceLimit?: number | null,
  _version?: number | null,
};

export type DeleteQuotasInput = {
  id: string,
  _version?: number | null,
};

export type CreateCampaignsInput = {
  CampaignID: string,
  CampaignUUID?: string | null,
  CampaignName: string,
  id?: string | null,
  _version?: number | null,
};

export type ModelCampaignsConditionInput = {
  CampaignID?: ModelIDInput | null,
  CampaignUUID?: ModelStringInput | null,
  CampaignName?: ModelStringInput | null,
  and?: Array< ModelCampaignsConditionInput | null > | null,
  or?: Array< ModelCampaignsConditionInput | null > | null,
  not?: ModelCampaignsConditionInput | null,
};

export type Campaigns = {
  __typename: "Campaigns",
  CampaignID: string,
  CampaignUUID?: string | null,
  CampaignName: string,
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCampaignsInput = {
  CampaignID?: string | null,
  CampaignUUID?: string | null,
  CampaignName?: string | null,
  id: string,
  _version?: number | null,
};

export type DeleteCampaignsInput = {
  id: string,
  _version?: number | null,
};

export type CreateReportsInput = {
  Name: string,
  ReportNamePattern: string,
  StartDate?: string | null,
  StartDateOffset?: number | null,
  FileDateOffset?: number | null,
  FuseAction: string,
  WithUtcTimeZoneInput?: boolean | null,
  WithReportingCycle?: boolean | null,
  id?: string | null,
  _version?: number | null,
};

export type ModelReportsConditionInput = {
  Name?: ModelStringInput | null,
  ReportNamePattern?: ModelStringInput | null,
  StartDate?: ModelStringInput | null,
  StartDateOffset?: ModelIntInput | null,
  FileDateOffset?: ModelIntInput | null,
  FuseAction?: ModelStringInput | null,
  WithUtcTimeZoneInput?: ModelBooleanInput | null,
  WithReportingCycle?: ModelBooleanInput | null,
  and?: Array< ModelReportsConditionInput | null > | null,
  or?: Array< ModelReportsConditionInput | null > | null,
  not?: ModelReportsConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Reports = {
  __typename: "Reports",
  Name: string,
  ReportNamePattern: string,
  // Holding StartDate to avoid migration problems
  StartDate?: string | null,
  StartDateOffset?: number | null,
  FileDateOffset?: number | null,
  FuseAction: string,
  WithUtcTimeZoneInput?: boolean | null,
  WithReportingCycle?: boolean | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateReportsInput = {
  Name?: string | null,
  ReportNamePattern?: string | null,
  StartDate?: string | null,
  StartDateOffset?: number | null,
  FileDateOffset?: number | null,
  FuseAction?: string | null,
  WithUtcTimeZoneInput?: boolean | null,
  WithReportingCycle?: boolean | null,
  id: string,
  _version?: number | null,
};

export type DeleteReportsInput = {
  id: string,
  _version?: number | null,
};

export type CreateReportParametersInput = {
  Name: string,
  Type: ReportParameterType,
  Source: string,
  id?: string | null,
  _version?: number | null,
};

export enum ReportParameterType {
  STORED_PROCEDURE = "STORED_PROCEDURE",
  FUNCTION = "FUNCTION",
  CAMPAIGN_COLUMN = "CAMPAIGN_COLUMN",
}


export type ModelReportParametersConditionInput = {
  Name?: ModelStringInput | null,
  Type?: ModelReportParameterTypeInput | null,
  Source?: ModelStringInput | null,
  and?: Array< ModelReportParametersConditionInput | null > | null,
  or?: Array< ModelReportParametersConditionInput | null > | null,
  not?: ModelReportParametersConditionInput | null,
};

export type ModelReportParameterTypeInput = {
  eq?: ReportParameterType | null,
  ne?: ReportParameterType | null,
};

export type ReportParameters = {
  __typename: "ReportParameters",
  Name: string,
  Type: ReportParameterType,
  Source: string,
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateReportParametersInput = {
  Name?: string | null,
  Type?: ReportParameterType | null,
  Source?: string | null,
  id: string,
  _version?: number | null,
};

export type DeleteReportParametersInput = {
  id: string,
  _version?: number | null,
};

export type CreateChecksInput = {
  id?: string | null,
  Type: CheckType,
  Date: number,
  CheckDate?: string | null,
  _version?: number | null,
};

export enum CheckType {
  CAMPAIGN = "CAMPAIGN",
  URL = "URL",
  QUOTA = "QUOTA",
}


export type ModelChecksConditionInput = {
  Type?: ModelCheckTypeInput | null,
  Date?: ModelIntInput | null,
  CheckDate?: ModelStringInput | null,
  and?: Array< ModelChecksConditionInput | null > | null,
  or?: Array< ModelChecksConditionInput | null > | null,
  not?: ModelChecksConditionInput | null,
};

export type ModelCheckTypeInput = {
  eq?: CheckType | null,
  ne?: CheckType | null,
};

export type Checks = {
  __typename: "Checks",
  id: string,
  Type: CheckType,
  Date: number,
  CheckDate?: string | null,
  CheckItems?: ModelCheckItemsConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelCheckItemsConnection = {
  __typename: "ModelCheckItemsConnection",
  items:  Array<CheckItems | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type CheckItems = {
  __typename: "CheckItems",
  id: string,
  Campaign?: Campaigns | null,
  Report?: Reports | null,
  checkItemsCheckId: string,
  Check?: Checks | null,
  Url: string,
  RerunTaskUrl?: string | null,
  Status?: CheckItemStatus | null,
  HttpResultCode?: string | null,
  BodyLength?: number | null,
  LastModified?: number | null,
  StartAt: number,
  FinishAt?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  checkItemsCampaignId?: string | null,
  checkItemsReportId?: string | null,
};

export enum CheckItemStatus {
  SUCCESS = "SUCCESS",
  NOT_FOUND = "NOT_FOUND",
  ERROR = "ERROR",
  NOT_CHECKED = "NOT_CHECKED",
}


export type UpdateChecksInput = {
  id: string,
  Type?: CheckType | null,
  Date?: number | null,
  CheckDate?: string | null,
  _version?: number | null,
};

export type DeleteChecksInput = {
  id: string,
  _version?: number | null,
};

export type CreateCheckItemsInput = {
  id?: string | null,
  checkItemsCheckId: string,
  Url: string,
  RerunTaskUrl?: string | null,
  Status?: CheckItemStatus | null,
  HttpResultCode?: string | null,
  BodyLength?: number | null,
  LastModified?: number | null,
  StartAt: number,
  FinishAt?: number | null,
  _version?: number | null,
  checkItemsCampaignId?: string | null,
  checkItemsReportId?: string | null,
};

export type ModelCheckItemsConditionInput = {
  checkItemsCheckId?: ModelIDInput | null,
  Url?: ModelStringInput | null,
  RerunTaskUrl?: ModelStringInput | null,
  Status?: ModelCheckItemStatusInput | null,
  HttpResultCode?: ModelStringInput | null,
  BodyLength?: ModelIntInput | null,
  LastModified?: ModelIntInput | null,
  StartAt?: ModelIntInput | null,
  FinishAt?: ModelIntInput | null,
  and?: Array< ModelCheckItemsConditionInput | null > | null,
  or?: Array< ModelCheckItemsConditionInput | null > | null,
  not?: ModelCheckItemsConditionInput | null,
  checkItemsCampaignId?: ModelIDInput | null,
  checkItemsReportId?: ModelIDInput | null,
};

export type ModelCheckItemStatusInput = {
  eq?: CheckItemStatus | null,
  ne?: CheckItemStatus | null,
};

export type UpdateCheckItemsInput = {
  id: string,
  checkItemsCheckId?: string | null,
  Url?: string | null,
  RerunTaskUrl?: string | null,
  Status?: CheckItemStatus | null,
  HttpResultCode?: string | null,
  BodyLength?: number | null,
  LastModified?: number | null,
  StartAt?: number | null,
  FinishAt?: number | null,
  _version?: number | null,
  checkItemsCampaignId?: string | null,
  checkItemsReportId?: string | null,
};

export type DeleteCheckItemsInput = {
  id: string,
  _version?: number | null,
};

export type CreateCampaignReportsInput = {
  campaignReportsCampaignId: string,
  Checked?: boolean | null,
  id?: string | null,
  _version?: number | null,
  campaignReportsReportId?: string | null,
};

export type ModelCampaignReportsConditionInput = {
  campaignReportsCampaignId?: ModelIDInput | null,
  Checked?: ModelBooleanInput | null,
  and?: Array< ModelCampaignReportsConditionInput | null > | null,
  or?: Array< ModelCampaignReportsConditionInput | null > | null,
  not?: ModelCampaignReportsConditionInput | null,
  campaignReportsReportId?: ModelIDInput | null,
};

export type CampaignReports = {
  __typename: "CampaignReports",
  campaignReportsCampaignId: string,
  Campaign?: Campaigns | null,
  Report?: Reports | null,
  Checked?: boolean | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  campaignReportsReportId?: string | null,
};

export type UpdateCampaignReportsInput = {
  campaignReportsCampaignId?: string | null,
  Checked?: boolean | null,
  id: string,
  _version?: number | null,
  campaignReportsReportId?: string | null,
};

export type DeleteCampaignReportsInput = {
  id: string,
  _version?: number | null,
};

export type ReportMetadata = {
  __typename: "ReportMetadata",
  LastModified: number,
  ContentLength: number,
  ContentType: string,
};

export type CampaignDto = {
  __typename: "CampaignDto",
  CampaignID: string,
  CampaignUUID?: string | null,
  CampaignName: string,
};

export type EnvironmentVariables = {
  __typename: "EnvironmentVariables",
  VOUCHER_ENGINE_URL: string,
  ENV: string,
  PROD_ENV_NAME: string,
};

export type GenericResponse = {
  __typename: "GenericResponse",
  statusCode: number,
  body: string,
};

export type ClientDto = {
  __typename: "ClientDto",
  ClientID: string,
  ClientUUID?: string | null,
  ClientName?: string | null,
  ClientContactPerson?: string | null,
};

export type ModelQuotaRunsFilterInput = {
  id?: ModelIDInput | null,
  quotaID?: ModelIDInput | null,
  balanceLimit?: ModelFloatInput | null,
  responseBody?: ModelStringInput | null,
  clientName?: ModelStringInput | null,
  clientId?: ModelIntInput | null,
  zendeskTicketId?: ModelIntInput | null,
  startAt?: ModelIntInput | null,
  finishAt?: ModelIntInput | null,
  lockedUntil?: ModelIntInput | null,
  and?: Array< ModelQuotaRunsFilterInput | null > | null,
  or?: Array< ModelQuotaRunsFilterInput | null > | null,
  not?: ModelQuotaRunsFilterInput | null,
};

export type ModelQuotasFilterInput = {
  id?: ModelIDInput | null,
  scheduleID?: ModelIDInput | null,
  clientId?: ModelIntInput | null,
  clientName?: ModelStringInput | null,
  balanceLimit?: ModelFloatInput | null,
  and?: Array< ModelQuotasFilterInput | null > | null,
  or?: Array< ModelQuotasFilterInput | null > | null,
  not?: ModelQuotasFilterInput | null,
};

export type ModelQuotasConnection = {
  __typename: "ModelQuotasConnection",
  items:  Array<Quotas | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCampaignsFilterInput = {
  CampaignID?: ModelIDInput | null,
  CampaignUUID?: ModelStringInput | null,
  CampaignName?: ModelStringInput | null,
  and?: Array< ModelCampaignsFilterInput | null > | null,
  or?: Array< ModelCampaignsFilterInput | null > | null,
  not?: ModelCampaignsFilterInput | null,
};

export type ModelCampaignsConnection = {
  __typename: "ModelCampaignsConnection",
  items:  Array<Campaigns | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelReportsFilterInput = {
  Name?: ModelStringInput | null,
  ReportNamePattern?: ModelStringInput | null,
  StartDate?: ModelStringInput | null,
  StartDateOffset?: ModelIntInput | null,
  FileDateOffset?: ModelIntInput | null,
  FuseAction?: ModelStringInput | null,
  WithUtcTimeZoneInput?: ModelBooleanInput | null,
  WithReportingCycle?: ModelBooleanInput | null,
  and?: Array< ModelReportsFilterInput | null > | null,
  or?: Array< ModelReportsFilterInput | null > | null,
  not?: ModelReportsFilterInput | null,
};

export type ModelReportsConnection = {
  __typename: "ModelReportsConnection",
  items:  Array<Reports | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelReportParametersFilterInput = {
  Name?: ModelStringInput | null,
  Type?: ModelReportParameterTypeInput | null,
  Source?: ModelStringInput | null,
  and?: Array< ModelReportParametersFilterInput | null > | null,
  or?: Array< ModelReportParametersFilterInput | null > | null,
  not?: ModelReportParametersFilterInput | null,
};

export type ModelReportParametersConnection = {
  __typename: "ModelReportParametersConnection",
  items:  Array<ReportParameters | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelChecksFilterInput = {
  id?: ModelIDInput | null,
  Type?: ModelCheckTypeInput | null,
  Date?: ModelIntInput | null,
  CheckDate?: ModelStringInput | null,
  and?: Array< ModelChecksFilterInput | null > | null,
  or?: Array< ModelChecksFilterInput | null > | null,
  not?: ModelChecksFilterInput | null,
};

export type ModelChecksConnection = {
  __typename: "ModelChecksConnection",
  items:  Array<Checks | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCheckItemsFilterInput = {
  id?: ModelIDInput | null,
  checkItemsCheckId?: ModelIDInput | null,
  Url?: ModelStringInput | null,
  RerunTaskUrl?: ModelStringInput | null,
  Status?: ModelCheckItemStatusInput | null,
  HttpResultCode?: ModelStringInput | null,
  BodyLength?: ModelIntInput | null,
  LastModified?: ModelIntInput | null,
  StartAt?: ModelIntInput | null,
  FinishAt?: ModelIntInput | null,
  and?: Array< ModelCheckItemsFilterInput | null > | null,
  or?: Array< ModelCheckItemsFilterInput | null > | null,
  not?: ModelCheckItemsFilterInput | null,
  checkItemsCampaignId?: ModelIDInput | null,
  checkItemsReportId?: ModelIDInput | null,
};

export type ModelCampaignReportsFilterInput = {
  campaignReportsCampaignId?: ModelIDInput | null,
  Checked?: ModelBooleanInput | null,
  and?: Array< ModelCampaignReportsFilterInput | null > | null,
  or?: Array< ModelCampaignReportsFilterInput | null > | null,
  not?: ModelCampaignReportsFilterInput | null,
  campaignReportsReportId?: ModelIDInput | null,
};

export type ModelCampaignReportsConnection = {
  __typename: "ModelCampaignReportsConnection",
  items:  Array<CampaignReports | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubscriptionQuotaRunsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  quotaID?: ModelSubscriptionIDInput | null,
  balanceLimit?: ModelSubscriptionFloatInput | null,
  responseBody?: ModelSubscriptionStringInput | null,
  clientName?: ModelSubscriptionStringInput | null,
  clientId?: ModelSubscriptionIntInput | null,
  zendeskTicketId?: ModelSubscriptionIntInput | null,
  startAt?: ModelSubscriptionIntInput | null,
  finishAt?: ModelSubscriptionIntInput | null,
  lockedUntil?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionQuotaRunsFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuotaRunsFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionQuotasFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  scheduleID?: ModelSubscriptionIDInput | null,
  clientId?: ModelSubscriptionIntInput | null,
  clientName?: ModelSubscriptionStringInput | null,
  balanceLimit?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionQuotasFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuotasFilterInput | null > | null,
};

export type ModelSubscriptionCampaignsFilterInput = {
  CampaignID?: ModelSubscriptionIDInput | null,
  CampaignUUID?: ModelSubscriptionStringInput | null,
  CampaignName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCampaignsFilterInput | null > | null,
  or?: Array< ModelSubscriptionCampaignsFilterInput | null > | null,
};

export type ModelSubscriptionReportsFilterInput = {
  Name?: ModelSubscriptionStringInput | null,
  ReportNamePattern?: ModelSubscriptionStringInput | null,
  StartDate?: ModelSubscriptionStringInput | null,
  StartDateOffset?: ModelSubscriptionIntInput | null,
  FileDateOffset?: ModelSubscriptionIntInput | null,
  FuseAction?: ModelSubscriptionStringInput | null,
  WithUtcTimeZoneInput?: ModelSubscriptionBooleanInput | null,
  WithReportingCycle?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionReportsFilterInput | null > | null,
  or?: Array< ModelSubscriptionReportsFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionReportParametersFilterInput = {
  Name?: ModelSubscriptionStringInput | null,
  Type?: ModelSubscriptionStringInput | null,
  Source?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReportParametersFilterInput | null > | null,
  or?: Array< ModelSubscriptionReportParametersFilterInput | null > | null,
};

export type ModelSubscriptionChecksFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  Type?: ModelSubscriptionStringInput | null,
  Date?: ModelSubscriptionIntInput | null,
  CheckDate?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChecksFilterInput | null > | null,
  or?: Array< ModelSubscriptionChecksFilterInput | null > | null,
};

export type ModelSubscriptionCheckItemsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  checkItemsCheckId?: ModelSubscriptionIDInput | null,
  Url?: ModelSubscriptionStringInput | null,
  RerunTaskUrl?: ModelSubscriptionStringInput | null,
  Status?: ModelSubscriptionStringInput | null,
  HttpResultCode?: ModelSubscriptionStringInput | null,
  BodyLength?: ModelSubscriptionIntInput | null,
  LastModified?: ModelSubscriptionIntInput | null,
  StartAt?: ModelSubscriptionIntInput | null,
  FinishAt?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionCheckItemsFilterInput | null > | null,
  or?: Array< ModelSubscriptionCheckItemsFilterInput | null > | null,
};

export type ModelSubscriptionCampaignReportsFilterInput = {
  campaignReportsCampaignId?: ModelSubscriptionIDInput | null,
  Checked?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionCampaignReportsFilterInput | null > | null,
  or?: Array< ModelSubscriptionCampaignReportsFilterInput | null > | null,
};

export type CreateQuotaRunsMutationVariables = {
  input: CreateQuotaRunsInput,
  condition?: ModelQuotaRunsConditionInput | null,
};

export type CreateQuotaRunsMutation = {
  createQuotaRuns?:  {
    __typename: "QuotaRuns",
    id: string,
    quotaID: string,
    balanceLimit?: number | null,
    responseBody?: string | null,
    clientName?: string | null,
    clientId?: number | null,
    zendeskTicketId?: number | null,
    startAt?: number | null,
    finishAt?: number | null,
    lockedUntil?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateQuotaRunsMutationVariables = {
  input: UpdateQuotaRunsInput,
  condition?: ModelQuotaRunsConditionInput | null,
};

export type UpdateQuotaRunsMutation = {
  updateQuotaRuns?:  {
    __typename: "QuotaRuns",
    id: string,
    quotaID: string,
    balanceLimit?: number | null,
    responseBody?: string | null,
    clientName?: string | null,
    clientId?: number | null,
    zendeskTicketId?: number | null,
    startAt?: number | null,
    finishAt?: number | null,
    lockedUntil?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteQuotaRunsMutationVariables = {
  input: DeleteQuotaRunsInput,
  condition?: ModelQuotaRunsConditionInput | null,
};

export type DeleteQuotaRunsMutation = {
  deleteQuotaRuns?:  {
    __typename: "QuotaRuns",
    id: string,
    quotaID: string,
    balanceLimit?: number | null,
    responseBody?: string | null,
    clientName?: string | null,
    clientId?: number | null,
    zendeskTicketId?: number | null,
    startAt?: number | null,
    finishAt?: number | null,
    lockedUntil?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateQuotasMutationVariables = {
  input: CreateQuotasInput,
  condition?: ModelQuotasConditionInput | null,
};

export type CreateQuotasMutation = {
  createQuotas?:  {
    __typename: "Quotas",
    id: string,
    scheduleID: string,
    clientId?: number | null,
    clientName?: string | null,
    balanceLimit?: number | null,
    withQuotaRuns?:  {
      __typename: "ModelQuotaRunsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateQuotasMutationVariables = {
  input: UpdateQuotasInput,
  condition?: ModelQuotasConditionInput | null,
};

export type UpdateQuotasMutation = {
  updateQuotas?:  {
    __typename: "Quotas",
    id: string,
    scheduleID: string,
    clientId?: number | null,
    clientName?: string | null,
    balanceLimit?: number | null,
    withQuotaRuns?:  {
      __typename: "ModelQuotaRunsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteQuotasMutationVariables = {
  input: DeleteQuotasInput,
  condition?: ModelQuotasConditionInput | null,
};

export type DeleteQuotasMutation = {
  deleteQuotas?:  {
    __typename: "Quotas",
    id: string,
    scheduleID: string,
    clientId?: number | null,
    clientName?: string | null,
    balanceLimit?: number | null,
    withQuotaRuns?:  {
      __typename: "ModelQuotaRunsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCampaignsMutationVariables = {
  input: CreateCampaignsInput,
  condition?: ModelCampaignsConditionInput | null,
};

export type CreateCampaignsMutation = {
  createCampaigns?:  {
    __typename: "Campaigns",
    CampaignID: string,
    CampaignUUID?: string | null,
    CampaignName: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCampaignsMutationVariables = {
  input: UpdateCampaignsInput,
  condition?: ModelCampaignsConditionInput | null,
};

export type UpdateCampaignsMutation = {
  updateCampaigns?:  {
    __typename: "Campaigns",
    CampaignID: string,
    CampaignUUID?: string | null,
    CampaignName: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCampaignsMutationVariables = {
  input: DeleteCampaignsInput,
  condition?: ModelCampaignsConditionInput | null,
};

export type DeleteCampaignsMutation = {
  deleteCampaigns?:  {
    __typename: "Campaigns",
    CampaignID: string,
    CampaignUUID?: string | null,
    CampaignName: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateReportsMutationVariables = {
  input: CreateReportsInput,
  condition?: ModelReportsConditionInput | null,
};

export type CreateReportsMutation = {
  createReports?:  {
    __typename: "Reports",
    Name: string,
    ReportNamePattern: string,
    // Holding StartDate to avoid migration problems
    StartDate?: string | null,
    StartDateOffset?: number | null,
    FileDateOffset?: number | null,
    FuseAction: string,
    WithUtcTimeZoneInput?: boolean | null,
    WithReportingCycle?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateReportsMutationVariables = {
  input: UpdateReportsInput,
  condition?: ModelReportsConditionInput | null,
};

export type UpdateReportsMutation = {
  updateReports?:  {
    __typename: "Reports",
    Name: string,
    ReportNamePattern: string,
    // Holding StartDate to avoid migration problems
    StartDate?: string | null,
    StartDateOffset?: number | null,
    FileDateOffset?: number | null,
    FuseAction: string,
    WithUtcTimeZoneInput?: boolean | null,
    WithReportingCycle?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteReportsMutationVariables = {
  input: DeleteReportsInput,
  condition?: ModelReportsConditionInput | null,
};

export type DeleteReportsMutation = {
  deleteReports?:  {
    __typename: "Reports",
    Name: string,
    ReportNamePattern: string,
    // Holding StartDate to avoid migration problems
    StartDate?: string | null,
    StartDateOffset?: number | null,
    FileDateOffset?: number | null,
    FuseAction: string,
    WithUtcTimeZoneInput?: boolean | null,
    WithReportingCycle?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateReportParametersMutationVariables = {
  input: CreateReportParametersInput,
  condition?: ModelReportParametersConditionInput | null,
};

export type CreateReportParametersMutation = {
  createReportParameters?:  {
    __typename: "ReportParameters",
    Name: string,
    Type: ReportParameterType,
    Source: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateReportParametersMutationVariables = {
  input: UpdateReportParametersInput,
  condition?: ModelReportParametersConditionInput | null,
};

export type UpdateReportParametersMutation = {
  updateReportParameters?:  {
    __typename: "ReportParameters",
    Name: string,
    Type: ReportParameterType,
    Source: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteReportParametersMutationVariables = {
  input: DeleteReportParametersInput,
  condition?: ModelReportParametersConditionInput | null,
};

export type DeleteReportParametersMutation = {
  deleteReportParameters?:  {
    __typename: "ReportParameters",
    Name: string,
    Type: ReportParameterType,
    Source: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateChecksMutationVariables = {
  input: CreateChecksInput,
  condition?: ModelChecksConditionInput | null,
};

export type CreateChecksMutation = {
  createChecks?:  {
    __typename: "Checks",
    id: string,
    Type: CheckType,
    Date: number,
    CheckDate?: string | null,
    CheckItems?:  {
      __typename: "ModelCheckItemsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateChecksMutationVariables = {
  input: UpdateChecksInput,
  condition?: ModelChecksConditionInput | null,
};

export type UpdateChecksMutation = {
  updateChecks?:  {
    __typename: "Checks",
    id: string,
    Type: CheckType,
    Date: number,
    CheckDate?: string | null,
    CheckItems?:  {
      __typename: "ModelCheckItemsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteChecksMutationVariables = {
  input: DeleteChecksInput,
  condition?: ModelChecksConditionInput | null,
};

export type DeleteChecksMutation = {
  deleteChecks?:  {
    __typename: "Checks",
    id: string,
    Type: CheckType,
    Date: number,
    CheckDate?: string | null,
    CheckItems?:  {
      __typename: "ModelCheckItemsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCheckItemsMutationVariables = {
  input: CreateCheckItemsInput,
  condition?: ModelCheckItemsConditionInput | null,
};

export type CreateCheckItemsMutation = {
  createCheckItems?:  {
    __typename: "CheckItems",
    id: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkItemsCheckId: string,
    Check?:  {
      __typename: "Checks",
      id: string,
      Type: CheckType,
      Date: number,
      CheckDate?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Url: string,
    RerunTaskUrl?: string | null,
    Status?: CheckItemStatus | null,
    HttpResultCode?: string | null,
    BodyLength?: number | null,
    LastModified?: number | null,
    StartAt: number,
    FinishAt?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    checkItemsCampaignId?: string | null,
    checkItemsReportId?: string | null,
  } | null,
};

export type UpdateCheckItemsMutationVariables = {
  input: UpdateCheckItemsInput,
  condition?: ModelCheckItemsConditionInput | null,
};

export type UpdateCheckItemsMutation = {
  updateCheckItems?:  {
    __typename: "CheckItems",
    id: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkItemsCheckId: string,
    Check?:  {
      __typename: "Checks",
      id: string,
      Type: CheckType,
      Date: number,
      CheckDate?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Url: string,
    RerunTaskUrl?: string | null,
    Status?: CheckItemStatus | null,
    HttpResultCode?: string | null,
    BodyLength?: number | null,
    LastModified?: number | null,
    StartAt: number,
    FinishAt?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    checkItemsCampaignId?: string | null,
    checkItemsReportId?: string | null,
  } | null,
};

export type DeleteCheckItemsMutationVariables = {
  input: DeleteCheckItemsInput,
  condition?: ModelCheckItemsConditionInput | null,
};

export type DeleteCheckItemsMutation = {
  deleteCheckItems?:  {
    __typename: "CheckItems",
    id: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkItemsCheckId: string,
    Check?:  {
      __typename: "Checks",
      id: string,
      Type: CheckType,
      Date: number,
      CheckDate?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Url: string,
    RerunTaskUrl?: string | null,
    Status?: CheckItemStatus | null,
    HttpResultCode?: string | null,
    BodyLength?: number | null,
    LastModified?: number | null,
    StartAt: number,
    FinishAt?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    checkItemsCampaignId?: string | null,
    checkItemsReportId?: string | null,
  } | null,
};

export type CreateCampaignReportsMutationVariables = {
  input: CreateCampaignReportsInput,
  condition?: ModelCampaignReportsConditionInput | null,
};

export type CreateCampaignReportsMutation = {
  createCampaignReports?:  {
    __typename: "CampaignReports",
    campaignReportsCampaignId: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Checked?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    campaignReportsReportId?: string | null,
  } | null,
};

export type UpdateCampaignReportsMutationVariables = {
  input: UpdateCampaignReportsInput,
  condition?: ModelCampaignReportsConditionInput | null,
};

export type UpdateCampaignReportsMutation = {
  updateCampaignReports?:  {
    __typename: "CampaignReports",
    campaignReportsCampaignId: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Checked?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    campaignReportsReportId?: string | null,
  } | null,
};

export type DeleteCampaignReportsMutationVariables = {
  input: DeleteCampaignReportsInput,
  condition?: ModelCampaignReportsConditionInput | null,
};

export type DeleteCampaignReportsMutation = {
  deleteCampaignReports?:  {
    __typename: "CampaignReports",
    campaignReportsCampaignId: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Checked?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    campaignReportsReportId?: string | null,
  } | null,
};

export type GetReportMetaDataQueryVariables = {
  reportUrl: string,
};

export type GetReportMetaDataQuery = {
  getReportMetaData?:  {
    __typename: "ReportMetadata",
    LastModified: number,
    ContentLength: number,
    ContentType: string,
  } | null,
};

export type GetCampaignsByNameQueryVariables = {
  name: string,
};

export type GetCampaignsByNameQuery = {
  getCampaignsByName?:  Array< {
    __typename: "CampaignDto",
    CampaignID: string,
    CampaignUUID?: string | null,
    CampaignName: string,
  } | null > | null,
};

export type GetEnvironmentVariablesQuery = {
  getEnvironmentVariables?:  {
    __typename: "EnvironmentVariables",
    VOUCHER_ENGINE_URL: string,
    ENV: string,
    PROD_ENV_NAME: string,
  } | null,
};

export type AddReportRerunQueueQueryVariables = {
  reportUrl: string,
  slackEmail: string,
};

export type AddReportRerunQueueQuery = {
  addReportRerunQueue?:  {
    __typename: "GenericResponse",
    statusCode: number,
    body: string,
  } | null,
};

export type GetClientsByNameQueryVariables = {
  name: string,
};

export type GetClientsByNameQuery = {
  getClientsByName?:  Array< {
    __typename: "ClientDto",
    ClientID: string,
    ClientUUID?: string | null,
    ClientName?: string | null,
    ClientContactPerson?: string | null,
  } | null > | null,
};

export type CheckCampaignsQueryVariables = {
  date?: number | null,
};

export type CheckCampaignsQuery = {
  checkCampaigns?: string | null,
};

export type GetQuotaRunsQueryVariables = {
  id: string,
};

export type GetQuotaRunsQuery = {
  getQuotaRuns?:  {
    __typename: "QuotaRuns",
    id: string,
    quotaID: string,
    balanceLimit?: number | null,
    responseBody?: string | null,
    clientName?: string | null,
    clientId?: number | null,
    zendeskTicketId?: number | null,
    startAt?: number | null,
    finishAt?: number | null,
    lockedUntil?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListQuotaRunsQueryVariables = {
  filter?: ModelQuotaRunsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuotaRunsQuery = {
  listQuotaRuns?:  {
    __typename: "ModelQuotaRunsConnection",
    items:  Array< {
      __typename: "QuotaRuns",
      id: string,
      quotaID: string,
      balanceLimit?: number | null,
      responseBody?: string | null,
      clientName?: string | null,
      clientId?: number | null,
      zendeskTicketId?: number | null,
      startAt?: number | null,
      finishAt?: number | null,
      lockedUntil?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQuotaRunsQueryVariables = {
  filter?: ModelQuotaRunsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQuotaRunsQuery = {
  syncQuotaRuns?:  {
    __typename: "ModelQuotaRunsConnection",
    items:  Array< {
      __typename: "QuotaRuns",
      id: string,
      quotaID: string,
      balanceLimit?: number | null,
      responseBody?: string | null,
      clientName?: string | null,
      clientId?: number | null,
      zendeskTicketId?: number | null,
      startAt?: number | null,
      finishAt?: number | null,
      lockedUntil?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetQuotasQueryVariables = {
  id: string,
};

export type GetQuotasQuery = {
  getQuotas?:  {
    __typename: "Quotas",
    id: string,
    scheduleID: string,
    clientId?: number | null,
    clientName?: string | null,
    balanceLimit?: number | null,
    withQuotaRuns?:  {
      __typename: "ModelQuotaRunsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListQuotasQueryVariables = {
  filter?: ModelQuotasFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuotasQuery = {
  listQuotas?:  {
    __typename: "ModelQuotasConnection",
    items:  Array< {
      __typename: "Quotas",
      id: string,
      scheduleID: string,
      clientId?: number | null,
      clientName?: string | null,
      balanceLimit?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQuotasQueryVariables = {
  filter?: ModelQuotasFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQuotasQuery = {
  syncQuotas?:  {
    __typename: "ModelQuotasConnection",
    items:  Array< {
      __typename: "Quotas",
      id: string,
      scheduleID: string,
      clientId?: number | null,
      clientName?: string | null,
      balanceLimit?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCampaignsQueryVariables = {
  id: string,
};

export type GetCampaignsQuery = {
  getCampaigns?:  {
    __typename: "Campaigns",
    CampaignID: string,
    CampaignUUID?: string | null,
    CampaignName: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCampaignsQueryVariables = {
  filter?: ModelCampaignsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignsQuery = {
  listCampaigns?:  {
    __typename: "ModelCampaignsConnection",
    items:  Array< {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCampaignsQueryVariables = {
  filter?: ModelCampaignsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCampaignsQuery = {
  syncCampaigns?:  {
    __typename: "ModelCampaignsConnection",
    items:  Array< {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReportsQueryVariables = {
  id: string,
};

export type GetReportsQuery = {
  getReports?:  {
    __typename: "Reports",
    Name: string,
    ReportNamePattern: string,
    // Holding StartDate to avoid migration problems
    StartDate?: string | null,
    StartDateOffset?: number | null,
    FileDateOffset?: number | null,
    FuseAction: string,
    WithUtcTimeZoneInput?: boolean | null,
    WithReportingCycle?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListReportsQueryVariables = {
  filter?: ModelReportsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportsConnection",
    items:  Array< {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReportsQueryVariables = {
  filter?: ModelReportsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReportsQuery = {
  syncReports?:  {
    __typename: "ModelReportsConnection",
    items:  Array< {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReportParametersQueryVariables = {
  id: string,
};

export type GetReportParametersQuery = {
  getReportParameters?:  {
    __typename: "ReportParameters",
    Name: string,
    Type: ReportParameterType,
    Source: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListReportParametersQueryVariables = {
  filter?: ModelReportParametersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReportParametersQuery = {
  listReportParameters?:  {
    __typename: "ModelReportParametersConnection",
    items:  Array< {
      __typename: "ReportParameters",
      Name: string,
      Type: ReportParameterType,
      Source: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReportParametersQueryVariables = {
  filter?: ModelReportParametersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReportParametersQuery = {
  syncReportParameters?:  {
    __typename: "ModelReportParametersConnection",
    items:  Array< {
      __typename: "ReportParameters",
      Name: string,
      Type: ReportParameterType,
      Source: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChecksQueryVariables = {
  id: string,
};

export type GetChecksQuery = {
  getChecks?:  {
    __typename: "Checks",
    id: string,
    Type: CheckType,
    Date: number,
    CheckDate?: string | null,
    CheckItems?:  {
      __typename: "ModelCheckItemsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListChecksQueryVariables = {
  filter?: ModelChecksFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChecksQuery = {
  listChecks?:  {
    __typename: "ModelChecksConnection",
    items:  Array< {
      __typename: "Checks",
      id: string,
      Type: CheckType,
      Date: number,
      CheckDate?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChecksQueryVariables = {
  filter?: ModelChecksFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChecksQuery = {
  syncChecks?:  {
    __typename: "ModelChecksConnection",
    items:  Array< {
      __typename: "Checks",
      id: string,
      Type: CheckType,
      Date: number,
      CheckDate?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChecksByTypeQueryVariables = {
  Type: CheckType,
  Date?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChecksFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChecksByTypeQuery = {
  checksByType?:  {
    __typename: "ModelChecksConnection",
    items:  Array< {
      __typename: "Checks",
      id: string,
      Type: CheckType,
      Date: number,
      CheckDate?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChecksByCheckDateQueryVariables = {
  CheckDate: string,
  Type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChecksFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChecksByCheckDateQuery = {
  checksByCheckDate?:  {
    __typename: "ModelChecksConnection",
    items:  Array< {
      __typename: "Checks",
      id: string,
      Type: CheckType,
      Date: number,
      CheckDate?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCheckItemsQueryVariables = {
  id: string,
};

export type GetCheckItemsQuery = {
  getCheckItems?:  {
    __typename: "CheckItems",
    id: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkItemsCheckId: string,
    Check?:  {
      __typename: "Checks",
      id: string,
      Type: CheckType,
      Date: number,
      CheckDate?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Url: string,
    RerunTaskUrl?: string | null,
    Status?: CheckItemStatus | null,
    HttpResultCode?: string | null,
    BodyLength?: number | null,
    LastModified?: number | null,
    StartAt: number,
    FinishAt?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    checkItemsCampaignId?: string | null,
    checkItemsReportId?: string | null,
  } | null,
};

export type ListCheckItemsQueryVariables = {
  filter?: ModelCheckItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCheckItemsQuery = {
  listCheckItems?:  {
    __typename: "ModelCheckItemsConnection",
    items:  Array< {
      __typename: "CheckItems",
      id: string,
      checkItemsCheckId: string,
      Url: string,
      RerunTaskUrl?: string | null,
      Status?: CheckItemStatus | null,
      HttpResultCode?: string | null,
      BodyLength?: number | null,
      LastModified?: number | null,
      StartAt: number,
      FinishAt?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      checkItemsCampaignId?: string | null,
      checkItemsReportId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCheckItemsQueryVariables = {
  filter?: ModelCheckItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCheckItemsQuery = {
  syncCheckItems?:  {
    __typename: "ModelCheckItemsConnection",
    items:  Array< {
      __typename: "CheckItems",
      id: string,
      checkItemsCheckId: string,
      Url: string,
      RerunTaskUrl?: string | null,
      Status?: CheckItemStatus | null,
      HttpResultCode?: string | null,
      BodyLength?: number | null,
      LastModified?: number | null,
      StartAt: number,
      FinishAt?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      checkItemsCampaignId?: string | null,
      checkItemsReportId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ByCheckIdQueryVariables = {
  checkItemsCheckId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCheckItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByCheckIdQuery = {
  byCheckId?:  {
    __typename: "ModelCheckItemsConnection",
    items:  Array< {
      __typename: "CheckItems",
      id: string,
      checkItemsCheckId: string,
      Url: string,
      RerunTaskUrl?: string | null,
      Status?: CheckItemStatus | null,
      HttpResultCode?: string | null,
      BodyLength?: number | null,
      LastModified?: number | null,
      StartAt: number,
      FinishAt?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      checkItemsCampaignId?: string | null,
      checkItemsReportId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCampaignReportsQueryVariables = {
  id: string,
};

export type GetCampaignReportsQuery = {
  getCampaignReports?:  {
    __typename: "CampaignReports",
    campaignReportsCampaignId: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Checked?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    campaignReportsReportId?: string | null,
  } | null,
};

export type ListCampaignReportsQueryVariables = {
  filter?: ModelCampaignReportsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampaignReportsQuery = {
  listCampaignReports?:  {
    __typename: "ModelCampaignReportsConnection",
    items:  Array< {
      __typename: "CampaignReports",
      campaignReportsCampaignId: string,
      Checked?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      campaignReportsReportId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCampaignReportsQueryVariables = {
  filter?: ModelCampaignReportsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCampaignReportsQuery = {
  syncCampaignReports?:  {
    __typename: "ModelCampaignReportsConnection",
    items:  Array< {
      __typename: "CampaignReports",
      campaignReportsCampaignId: string,
      Checked?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      campaignReportsReportId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ByCampaignIdQueryVariables = {
  campaignReportsCampaignId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampaignReportsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByCampaignIdQuery = {
  byCampaignId?:  {
    __typename: "ModelCampaignReportsConnection",
    items:  Array< {
      __typename: "CampaignReports",
      campaignReportsCampaignId: string,
      Checked?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      campaignReportsReportId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateQuotaRunsSubscriptionVariables = {
  filter?: ModelSubscriptionQuotaRunsFilterInput | null,
};

export type OnCreateQuotaRunsSubscription = {
  onCreateQuotaRuns?:  {
    __typename: "QuotaRuns",
    id: string,
    quotaID: string,
    balanceLimit?: number | null,
    responseBody?: string | null,
    clientName?: string | null,
    clientId?: number | null,
    zendeskTicketId?: number | null,
    startAt?: number | null,
    finishAt?: number | null,
    lockedUntil?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateQuotaRunsSubscriptionVariables = {
  filter?: ModelSubscriptionQuotaRunsFilterInput | null,
};

export type OnUpdateQuotaRunsSubscription = {
  onUpdateQuotaRuns?:  {
    __typename: "QuotaRuns",
    id: string,
    quotaID: string,
    balanceLimit?: number | null,
    responseBody?: string | null,
    clientName?: string | null,
    clientId?: number | null,
    zendeskTicketId?: number | null,
    startAt?: number | null,
    finishAt?: number | null,
    lockedUntil?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteQuotaRunsSubscriptionVariables = {
  filter?: ModelSubscriptionQuotaRunsFilterInput | null,
};

export type OnDeleteQuotaRunsSubscription = {
  onDeleteQuotaRuns?:  {
    __typename: "QuotaRuns",
    id: string,
    quotaID: string,
    balanceLimit?: number | null,
    responseBody?: string | null,
    clientName?: string | null,
    clientId?: number | null,
    zendeskTicketId?: number | null,
    startAt?: number | null,
    finishAt?: number | null,
    lockedUntil?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateQuotasSubscriptionVariables = {
  filter?: ModelSubscriptionQuotasFilterInput | null,
};

export type OnCreateQuotasSubscription = {
  onCreateQuotas?:  {
    __typename: "Quotas",
    id: string,
    scheduleID: string,
    clientId?: number | null,
    clientName?: string | null,
    balanceLimit?: number | null,
    withQuotaRuns?:  {
      __typename: "ModelQuotaRunsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateQuotasSubscriptionVariables = {
  filter?: ModelSubscriptionQuotasFilterInput | null,
};

export type OnUpdateQuotasSubscription = {
  onUpdateQuotas?:  {
    __typename: "Quotas",
    id: string,
    scheduleID: string,
    clientId?: number | null,
    clientName?: string | null,
    balanceLimit?: number | null,
    withQuotaRuns?:  {
      __typename: "ModelQuotaRunsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteQuotasSubscriptionVariables = {
  filter?: ModelSubscriptionQuotasFilterInput | null,
};

export type OnDeleteQuotasSubscription = {
  onDeleteQuotas?:  {
    __typename: "Quotas",
    id: string,
    scheduleID: string,
    clientId?: number | null,
    clientName?: string | null,
    balanceLimit?: number | null,
    withQuotaRuns?:  {
      __typename: "ModelQuotaRunsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCampaignsSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignsFilterInput | null,
};

export type OnCreateCampaignsSubscription = {
  onCreateCampaigns?:  {
    __typename: "Campaigns",
    CampaignID: string,
    CampaignUUID?: string | null,
    CampaignName: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCampaignsSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignsFilterInput | null,
};

export type OnUpdateCampaignsSubscription = {
  onUpdateCampaigns?:  {
    __typename: "Campaigns",
    CampaignID: string,
    CampaignUUID?: string | null,
    CampaignName: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCampaignsSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignsFilterInput | null,
};

export type OnDeleteCampaignsSubscription = {
  onDeleteCampaigns?:  {
    __typename: "Campaigns",
    CampaignID: string,
    CampaignUUID?: string | null,
    CampaignName: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateReportsSubscriptionVariables = {
  filter?: ModelSubscriptionReportsFilterInput | null,
};

export type OnCreateReportsSubscription = {
  onCreateReports?:  {
    __typename: "Reports",
    Name: string,
    ReportNamePattern: string,
    // Holding StartDate to avoid migration problems
    StartDate?: string | null,
    StartDateOffset?: number | null,
    FileDateOffset?: number | null,
    FuseAction: string,
    WithUtcTimeZoneInput?: boolean | null,
    WithReportingCycle?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateReportsSubscriptionVariables = {
  filter?: ModelSubscriptionReportsFilterInput | null,
};

export type OnUpdateReportsSubscription = {
  onUpdateReports?:  {
    __typename: "Reports",
    Name: string,
    ReportNamePattern: string,
    // Holding StartDate to avoid migration problems
    StartDate?: string | null,
    StartDateOffset?: number | null,
    FileDateOffset?: number | null,
    FuseAction: string,
    WithUtcTimeZoneInput?: boolean | null,
    WithReportingCycle?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteReportsSubscriptionVariables = {
  filter?: ModelSubscriptionReportsFilterInput | null,
};

export type OnDeleteReportsSubscription = {
  onDeleteReports?:  {
    __typename: "Reports",
    Name: string,
    ReportNamePattern: string,
    // Holding StartDate to avoid migration problems
    StartDate?: string | null,
    StartDateOffset?: number | null,
    FileDateOffset?: number | null,
    FuseAction: string,
    WithUtcTimeZoneInput?: boolean | null,
    WithReportingCycle?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateReportParametersSubscriptionVariables = {
  filter?: ModelSubscriptionReportParametersFilterInput | null,
};

export type OnCreateReportParametersSubscription = {
  onCreateReportParameters?:  {
    __typename: "ReportParameters",
    Name: string,
    Type: ReportParameterType,
    Source: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateReportParametersSubscriptionVariables = {
  filter?: ModelSubscriptionReportParametersFilterInput | null,
};

export type OnUpdateReportParametersSubscription = {
  onUpdateReportParameters?:  {
    __typename: "ReportParameters",
    Name: string,
    Type: ReportParameterType,
    Source: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteReportParametersSubscriptionVariables = {
  filter?: ModelSubscriptionReportParametersFilterInput | null,
};

export type OnDeleteReportParametersSubscription = {
  onDeleteReportParameters?:  {
    __typename: "ReportParameters",
    Name: string,
    Type: ReportParameterType,
    Source: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateChecksSubscriptionVariables = {
  filter?: ModelSubscriptionChecksFilterInput | null,
};

export type OnCreateChecksSubscription = {
  onCreateChecks?:  {
    __typename: "Checks",
    id: string,
    Type: CheckType,
    Date: number,
    CheckDate?: string | null,
    CheckItems?:  {
      __typename: "ModelCheckItemsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateChecksSubscriptionVariables = {
  filter?: ModelSubscriptionChecksFilterInput | null,
};

export type OnUpdateChecksSubscription = {
  onUpdateChecks?:  {
    __typename: "Checks",
    id: string,
    Type: CheckType,
    Date: number,
    CheckDate?: string | null,
    CheckItems?:  {
      __typename: "ModelCheckItemsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteChecksSubscriptionVariables = {
  filter?: ModelSubscriptionChecksFilterInput | null,
};

export type OnDeleteChecksSubscription = {
  onDeleteChecks?:  {
    __typename: "Checks",
    id: string,
    Type: CheckType,
    Date: number,
    CheckDate?: string | null,
    CheckItems?:  {
      __typename: "ModelCheckItemsConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCheckItemsSubscriptionVariables = {
  filter?: ModelSubscriptionCheckItemsFilterInput | null,
};

export type OnCreateCheckItemsSubscription = {
  onCreateCheckItems?:  {
    __typename: "CheckItems",
    id: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkItemsCheckId: string,
    Check?:  {
      __typename: "Checks",
      id: string,
      Type: CheckType,
      Date: number,
      CheckDate?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Url: string,
    RerunTaskUrl?: string | null,
    Status?: CheckItemStatus | null,
    HttpResultCode?: string | null,
    BodyLength?: number | null,
    LastModified?: number | null,
    StartAt: number,
    FinishAt?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    checkItemsCampaignId?: string | null,
    checkItemsReportId?: string | null,
  } | null,
};

export type OnUpdateCheckItemsSubscriptionVariables = {
  filter?: ModelSubscriptionCheckItemsFilterInput | null,
};

export type OnUpdateCheckItemsSubscription = {
  onUpdateCheckItems?:  {
    __typename: "CheckItems",
    id: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkItemsCheckId: string,
    Check?:  {
      __typename: "Checks",
      id: string,
      Type: CheckType,
      Date: number,
      CheckDate?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Url: string,
    RerunTaskUrl?: string | null,
    Status?: CheckItemStatus | null,
    HttpResultCode?: string | null,
    BodyLength?: number | null,
    LastModified?: number | null,
    StartAt: number,
    FinishAt?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    checkItemsCampaignId?: string | null,
    checkItemsReportId?: string | null,
  } | null,
};

export type OnDeleteCheckItemsSubscriptionVariables = {
  filter?: ModelSubscriptionCheckItemsFilterInput | null,
};

export type OnDeleteCheckItemsSubscription = {
  onDeleteCheckItems?:  {
    __typename: "CheckItems",
    id: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkItemsCheckId: string,
    Check?:  {
      __typename: "Checks",
      id: string,
      Type: CheckType,
      Date: number,
      CheckDate?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Url: string,
    RerunTaskUrl?: string | null,
    Status?: CheckItemStatus | null,
    HttpResultCode?: string | null,
    BodyLength?: number | null,
    LastModified?: number | null,
    StartAt: number,
    FinishAt?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    checkItemsCampaignId?: string | null,
    checkItemsReportId?: string | null,
  } | null,
};

export type OnCreateCampaignReportsSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignReportsFilterInput | null,
};

export type OnCreateCampaignReportsSubscription = {
  onCreateCampaignReports?:  {
    __typename: "CampaignReports",
    campaignReportsCampaignId: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Checked?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    campaignReportsReportId?: string | null,
  } | null,
};

export type OnUpdateCampaignReportsSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignReportsFilterInput | null,
};

export type OnUpdateCampaignReportsSubscription = {
  onUpdateCampaignReports?:  {
    __typename: "CampaignReports",
    campaignReportsCampaignId: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Checked?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    campaignReportsReportId?: string | null,
  } | null,
};

export type OnDeleteCampaignReportsSubscriptionVariables = {
  filter?: ModelSubscriptionCampaignReportsFilterInput | null,
};

export type OnDeleteCampaignReportsSubscription = {
  onDeleteCampaignReports?:  {
    __typename: "CampaignReports",
    campaignReportsCampaignId: string,
    Campaign?:  {
      __typename: "Campaigns",
      CampaignID: string,
      CampaignUUID?: string | null,
      CampaignName: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Report?:  {
      __typename: "Reports",
      Name: string,
      ReportNamePattern: string,
      // Holding StartDate to avoid migration problems
      StartDate?: string | null,
      StartDateOffset?: number | null,
      FileDateOffset?: number | null,
      FuseAction: string,
      WithUtcTimeZoneInput?: boolean | null,
      WithReportingCycle?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Checked?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    campaignReportsReportId?: string | null,
  } | null,
};
