/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReportMetaData = /* GraphQL */ `
  query GetReportMetaData($reportUrl: String!) {
    getReportMetaData(reportUrl: $reportUrl) {
      LastModified
      ContentLength
      ContentType
    }
  }
`;
export const getCampaignsByName = /* GraphQL */ `
  query GetCampaignsByName($name: String!) {
    getCampaignsByName(name: $name) {
      CampaignID
      CampaignUUID
      CampaignName
    }
  }
`;
export const getEnvironmentVariables = /* GraphQL */ `
  query GetEnvironmentVariables {
    getEnvironmentVariables {
      VOUCHER_ENGINE_URL
      ENV
      PROD_ENV_NAME
    }
  }
`;
export const addReportRerunQueue = /* GraphQL */ `
  query AddReportRerunQueue($reportUrl: String!, $slackEmail: String!) {
    addReportRerunQueue(reportUrl: $reportUrl, slackEmail: $slackEmail) {
      statusCode
      body
    }
  }
`;
export const getClientsByName = /* GraphQL */ `
  query GetClientsByName($name: String!) {
    getClientsByName(name: $name) {
      ClientID
      ClientUUID
      ClientName
      ClientContactPerson
    }
  }
`;
export const checkCampaigns = /* GraphQL */ `
  query CheckCampaigns($date: AWSTimestamp) {
    checkCampaigns(date: $date)
  }
`;
export const getQuotaRuns = /* GraphQL */ `
  query GetQuotaRuns($id: ID!) {
    getQuotaRuns(id: $id) {
      id
      quotaID
      balanceLimit
      responseBody
      clientName
      clientId
      zendeskTicketId
      startAt
      finishAt
      lockedUntil
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listQuotaRuns = /* GraphQL */ `
  query ListQuotaRuns(
    $filter: ModelQuotaRunsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuotaRuns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quotaID
        balanceLimit
        responseBody
        clientName
        clientId
        zendeskTicketId
        startAt
        finishAt
        lockedUntil
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncQuotaRuns = /* GraphQL */ `
  query SyncQuotaRuns(
    $filter: ModelQuotaRunsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuotaRuns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        quotaID
        balanceLimit
        responseBody
        clientName
        clientId
        zendeskTicketId
        startAt
        finishAt
        lockedUntil
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getQuotas = /* GraphQL */ `
  query GetQuotas($id: ID!) {
    getQuotas(id: $id) {
      id
      scheduleID
      clientId
      clientName
      balanceLimit
      withQuotaRuns {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listQuotas = /* GraphQL */ `
  query ListQuotas(
    $filter: ModelQuotasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuotas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        scheduleID
        clientId
        clientName
        balanceLimit
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncQuotas = /* GraphQL */ `
  query SyncQuotas(
    $filter: ModelQuotasFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuotas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        scheduleID
        clientId
        clientName
        balanceLimit
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCampaigns = /* GraphQL */ `
  query GetCampaigns($id: ID!) {
    getCampaigns(id: $id) {
      CampaignID
      CampaignUUID
      CampaignName
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        CampaignID
        CampaignUUID
        CampaignName
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCampaigns = /* GraphQL */ `
  query SyncCampaigns(
    $filter: ModelCampaignsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCampaigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        CampaignID
        CampaignUUID
        CampaignName
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReports = /* GraphQL */ `
  query GetReports($id: ID!) {
    getReports(id: $id) {
      Name
      ReportNamePattern
      StartDate
      StartDateOffset
      FileDateOffset
      FuseAction
      WithUtcTimeZoneInput
      WithReportingCycle
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Name
        ReportNamePattern
        StartDate
        StartDateOffset
        FileDateOffset
        FuseAction
        WithUtcTimeZoneInput
        WithReportingCycle
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReports = /* GraphQL */ `
  query SyncReports(
    $filter: ModelReportsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        Name
        ReportNamePattern
        StartDate
        StartDateOffset
        FileDateOffset
        FuseAction
        WithUtcTimeZoneInput
        WithReportingCycle
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReportParameters = /* GraphQL */ `
  query GetReportParameters($id: ID!) {
    getReportParameters(id: $id) {
      Name
      Type
      Source
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReportParameters = /* GraphQL */ `
  query ListReportParameters(
    $filter: ModelReportParametersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Name
        Type
        Source
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReportParameters = /* GraphQL */ `
  query SyncReportParameters(
    $filter: ModelReportParametersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReportParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        Name
        Type
        Source
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChecks = /* GraphQL */ `
  query GetChecks($id: ID!) {
    getChecks(id: $id) {
      id
      Type
      Date
      CheckDate
      CheckItems {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChecks = /* GraphQL */ `
  query ListChecks(
    $filter: ModelChecksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChecks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Type
        Date
        CheckDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChecks = /* GraphQL */ `
  query SyncChecks(
    $filter: ModelChecksFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChecks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Type
        Date
        CheckDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const checksByType = /* GraphQL */ `
  query ChecksByType(
    $Type: CheckType!
    $Date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChecksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checksByType(
      Type: $Type
      Date: $Date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Type
        Date
        CheckDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const checksByCheckDate = /* GraphQL */ `
  query ChecksByCheckDate(
    $CheckDate: AWSDate!
    $Type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChecksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checksByCheckDate(
      CheckDate: $CheckDate
      Type: $Type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Type
        Date
        CheckDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCheckItems = /* GraphQL */ `
  query GetCheckItems($id: ID!) {
    getCheckItems(id: $id) {
      id
      Campaign {
        CampaignID
        CampaignUUID
        CampaignName
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Report {
        Name
        ReportNamePattern
        StartDate
        StartDateOffset
        FileDateOffset
        FuseAction
        WithUtcTimeZoneInput
        WithReportingCycle
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      checkItemsCheckId
      Check {
        id
        Type
        Date
        CheckDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Url
      RerunTaskUrl
      Status
      HttpResultCode
      BodyLength
      LastModified
      StartAt
      FinishAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      checkItemsCampaignId
      checkItemsReportId
    }
  }
`;
export const listCheckItems = /* GraphQL */ `
  query ListCheckItems(
    $filter: ModelCheckItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        checkItemsCheckId
        Url
        RerunTaskUrl
        Status
        HttpResultCode
        BodyLength
        LastModified
        StartAt
        FinishAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        checkItemsCampaignId
        checkItemsReportId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCheckItems = /* GraphQL */ `
  query SyncCheckItems(
    $filter: ModelCheckItemsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCheckItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        checkItemsCheckId
        Url
        RerunTaskUrl
        Status
        HttpResultCode
        BodyLength
        LastModified
        StartAt
        FinishAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        checkItemsCampaignId
        checkItemsReportId
      }
      nextToken
      startedAt
    }
  }
`;
export const byCheckId = /* GraphQL */ `
  query ByCheckId(
    $checkItemsCheckId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCheckId(
      checkItemsCheckId: $checkItemsCheckId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        checkItemsCheckId
        Url
        RerunTaskUrl
        Status
        HttpResultCode
        BodyLength
        LastModified
        StartAt
        FinishAt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        checkItemsCampaignId
        checkItemsReportId
      }
      nextToken
      startedAt
    }
  }
`;
export const getCampaignReports = /* GraphQL */ `
  query GetCampaignReports($id: ID!) {
    getCampaignReports(id: $id) {
      campaignReportsCampaignId
      Campaign {
        CampaignID
        CampaignUUID
        CampaignName
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Report {
        Name
        ReportNamePattern
        StartDate
        StartDateOffset
        FileDateOffset
        FuseAction
        WithUtcTimeZoneInput
        WithReportingCycle
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Checked
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      campaignReportsReportId
    }
  }
`;
export const listCampaignReports = /* GraphQL */ `
  query ListCampaignReports(
    $filter: ModelCampaignReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        campaignReportsCampaignId
        Checked
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        campaignReportsReportId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCampaignReports = /* GraphQL */ `
  query SyncCampaignReports(
    $filter: ModelCampaignReportsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCampaignReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        campaignReportsCampaignId
        Checked
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        campaignReportsReportId
      }
      nextToken
      startedAt
    }
  }
`;
export const byCampaignId = /* GraphQL */ `
  query ByCampaignId(
    $campaignReportsCampaignId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCampaignId(
      campaignReportsCampaignId: $campaignReportsCampaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        campaignReportsCampaignId
        Checked
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        campaignReportsReportId
      }
      nextToken
      startedAt
    }
  }
`;
