// @ts-nocheck
import './UpdateIncident.css';

import React, { useState, useEffect } from 'react';

import { Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

import ContentEditable from 'react-contenteditable';

import { Link, useParams } from 'react-router-dom';
import Logo from './exclamation-mark-svgrepo-com.svg';
import Logox from './loading.gif';

const initialState = {
  incidentName: '',
  incidentBody: 'We are currently investigating this issue.',
  incidentStatus: 'investigating'
};

/**
 *
 * Available data mappings:
 *
 * cachedData['pageIdToPage']
 *
 * cachedData['pageIdToIncidentIds']
 * cachedData['incidentIdToIncident']
 * cachedData['incidentIdToPageId']
 * cachedData['internalIdToIncidentIds']
 *
 * cachedData['pageIdToComponentIds']
 * cachedData['componentIdToComponent']
 * cachedData['componentIdToPageId']
 * cachedData['componentNameToPageIdComponentId']
 */
function UpdateIncident() {
  const [cachedData, setCachedData] = useState({});
  const [shouldLoadData, setShouldLoadData] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [componentCheckboxSelected, setComponentCheckboxSelected] = useState({});
  const [formState, setFormState] = useState(initialState);
  const [shouldShowSelectAllComponents, setShouldShowSelectAllComponents] = useState(false);
  const [shouldShowSelectAllPages, setShouldShowSelectAllPages] = useState(false);
  const [pageCheckboxSelected, setPageCheckboxSelected] = useState({});
  const [updateResult, setUpdateResult] = useState([]);

  const { internalId } = useParams();
  const delayBetweenApiCalls = localStorage.getItem('delayBetweenCalls') ? localStorage.getItem('delayBetweenCalls') : 1000;

  function setInput(key, value) {
    setFormState((fState) => ({ ...fState, [key]: value }));
  }

  function setComponentStatus(componentName, status) {
    if (!('componentNameToStatus' in formState)) {
      formState.componentNameToStatus = {};
    }
    formState.componentNameToStatus[componentName] = status;
    setInput('componentNameToStatus', formState.componentNameToStatus);
  }

  function loadIncidentDataToForm(intId) {
    // console.log('inside loadIncidentDataToForm' + intId);
    const incidentIds = cachedData.internalIdToIncidentIds[intId];
    const incidentId = incidentIds[0];
    const incident = cachedData.incidentIdToIncident[incidentId];

    setInput('incidentName', incident.name);
    setInput('incidentStatus', incident.status);
    setInput('incidentBody', incident.incident_updates[0].body);

    const componentIdToStatus = {};
    incidentIds.forEach((anIncidentId) => {
      const anIncident = cachedData.incidentIdToIncident[anIncidentId];
      anIncident.components.forEach((component) => {
        componentIdToStatus[component.id] = component.status;
      });
    });

    const componentNameToStatus = {};
    Object.keys(componentIdToStatus).forEach((componentId) => {
      const componentName = cachedData.componentIdToComponent[componentId].name;
      componentNameToStatus[componentName] = componentIdToStatus[componentId];
    });
    setInput('componentNameToStatus', componentNameToStatus);

    // console.log(componentNameToStatus);

    const selection = {};
    Object.keys(cachedData.pageIdToPage).forEach((pageId) => {
      selection[pageId] = false;
    });
    incidentIds.forEach((anIncidentId) => {
      const pageId = cachedData.incidentIdToPageId[anIncidentId];
      selection[pageId] = true;
    });
    setPageCheckboxSelected(selection);

    console.log('incident:');
    console.log(incident);
  }

  const apiUrl = 'https://incidents.test.tutuka.cloud/api';

  async function loadData() {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    // load incidents

    // Load Pages
    fetch(`${apiUrl}/v1/pages`, {
      method: 'GET',
      headers: {
        authorization: token,
        Accept: 'application/json'
      }
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((json) => {
        const pageIdToPage = {};
        json.forEach((page) => {
          pageIdToPage[page.id] = page;
        });
        cachedData.pageIdToPage = pageIdToPage;
        return pageIdToPage;
      })
      .then((pageIdToPage) => {
        // Load Unresolved incidents
        const pageIdToIncidentIds = [];
        const incidentIdToIncident = {};
        const incidentIdToPageId = {};
        const internalIdToIncidentIds = {};
        const foreignIncidentIds = [];

        setInput('loadState', '[Phase 1] Fetching unresolved incidents...');

        const requests = Object.keys(pageIdToPage).map((pageId, index) =>
          new Promise((resolve) => {
            setTimeout(resolve, index * delayBetweenApiCalls);
          })
            .then(() =>
              fetch(`${apiUrl}/v1/pages/${pageId}/incidents/unresolved?page=1`, {
                method: 'GET',
                headers: {
                  authorization: token,
                  Accept: 'application/json'
                }
              })
            )
            .then((res) => {
              setInput('loadState', `[Phase 1] Incidents fetched for the page "${pageIdToPage[pageId].name}" [${index + 1}/${Object.keys(pageIdToPage).length}]`);
              return res.json();
            })
            .then((incidents) => {
              incidents.forEach((incident) => {
                // console.log(incident);
                if (!(pageId in pageIdToIncidentIds)) {
                  pageIdToIncidentIds[pageId] = [];
                }
                pageIdToIncidentIds[pageId].push(incident.id);
                incidentIdToIncident[incident.id] = incident;
                incidentIdToPageId[incident.id] = pageId;

                if (incident.metadata && incident.metadata.id && incident.metadata.id.value) {
                  if (!(incident.metadata.id.value in internalIdToIncidentIds)) {
                    internalIdToIncidentIds[incident.metadata.id.value] = [];
                  }
                  internalIdToIncidentIds[incident.metadata.id.value].push(incident.id);
                } else {
                  // This incident was not created by the tool.
                  foreignIncidentIds.push(incident.id);
                }
              });
            })
            .then(() => {
              setInput('loadState', `[Phase 1] Incidents loaded for the page "${pageIdToPage[pageId].name}" [${index + 1}/${Object.keys(pageIdToPage).length}]`);
            })
        );
        return Promise.all(requests).then(() => {
          cachedData.pageIdToIncidentIds = pageIdToIncidentIds;
          cachedData.incidentIdToIncident = incidentIdToIncident;
          cachedData.incidentIdToPageId = incidentIdToPageId;
          cachedData.internalIdToIncidentIds = internalIdToIncidentIds;
          cachedData.foreignIncidentIds = foreignIncidentIds;
        });
      })
      .then(() => {
        setCachedData(cachedData);
      })
      .then(() => {
        const pageIdToComponentIds = {};
        const componentIdToPageId = {};
        const componentIdToComponent = {};
        const componentNameToPageIdComponentId = {};
        const componentNameToStatus = {};
        const componentNamesNotInAllPages = {};

        setInput('loadState', '[Phase 2] Loading components...');

        const requests = Object.keys(cachedData.pageIdToPage).map((pageId, index) =>
          new Promise((resolve) => {
            setTimeout(resolve, index * delayBetweenApiCalls);
          })
            .then(() =>
              fetch(`${apiUrl}/v1/pages/${pageId}/components?page=1`, {
                method: 'GET',
                headers: {
                  authorization: token,
                  Accept: 'application/json'
                }
              })
            )
            .then((res) => res.json())
            .then((components) => {
              components.forEach((component) => {
                if (!(component.page_id in pageIdToComponentIds)) {
                  pageIdToComponentIds[component.page_id] = [];
                }
                pageIdToComponentIds[component.page_id].push(component.id);
                componentIdToComponent[component.id] = component;
                componentIdToPageId[component.id] = component.page_id;

                if (!(component.name in componentNameToPageIdComponentId)) {
                  componentNameToPageIdComponentId[component.name] = {};
                }
                componentNameToPageIdComponentId[component.name][component.page_id] = component.id;

                componentNameToStatus[component.name] = 'operational';
              });
            })
            .then(() => {
              setInput('loadState', `[Phase 2] Loaded components for the page "${cachedData.pageIdToPage[pageId].name}" [${index + 1}/${Object.keys(cachedData.pageIdToPage).length}]`);
            })
        );

        return Promise.all(requests)
          .then(() => {
            Object.keys(componentNameToPageIdComponentId).forEach((componentName) => {
              if (Object.keys(cachedData.pageIdToPage).length !== Object.keys(componentNameToPageIdComponentId[componentName]).length) {
                componentNamesNotInAllPages[componentName] = componentName;
              }
            });
          })
          .then(() => {
            cachedData.pageIdToComponentIds = pageIdToComponentIds;
            cachedData.componentIdToComponent = componentIdToComponent;
            cachedData.componentIdToPageId = componentIdToPageId;
            cachedData.componentNameToPageIdComponentId = componentNameToPageIdComponentId;
            cachedData.componentNamesNotInAllPages = componentNamesNotInAllPages;
            setInput('componentNameToStatus', componentNameToStatus);
          });
      })
      .then(() => {
        const incidentTemplateNameToIncidentTemplate = {};

        setInput('loadState', '[Phase 3] Loading templates...');

        const requests = Object.keys(cachedData.pageIdToPage).map((pageId, index) =>
          new Promise((resolve) => {
            setTimeout(resolve, index * delayBetweenApiCalls);
          })
            .then(() =>
              fetch(`${apiUrl}/v1/pages/${pageId}/incident_templates?page=1`, {
                method: 'GET',
                headers: {
                  authorization: token,
                  Accept: 'application/json'
                }
              })
            )
            .then((res) => res.json())
            .then((templates) => {
              templates.forEach((template) => {
                incidentTemplateNameToIncidentTemplate[template.name] = template;
              });
            })
            .then(() => {
              setInput('loadState', `[Phase 3] Loaded templates for the page "${cachedData.pageIdToPage[pageId].name}" [${index + 1}/${Object.keys(cachedData.pageIdToPage).length}]`);
            })
        );

        return Promise.all(requests).then(() => {
          cachedData.incidentTemplateNameToIncidentTemplate = incidentTemplateNameToIncidentTemplate;
        });
      })
      .then(() => {
        setInput('loadState', 'Done');

        setCachedData(cachedData);
        if (internalId in cachedData.internalIdToIncidentIds) {
          loadIncidentDataToForm(internalId);
        }
        setDataLoading(false);
      });
  }

  const dumpData = () => {
    console.log(cachedData);
  };

  function toggleComponentCheckboxSelected(id) {
    setComponentCheckboxSelected({
      ...componentCheckboxSelected,
      [id]: id in componentCheckboxSelected ? !componentCheckboxSelected[id] : true
    });
    return componentCheckboxSelected[id];
  }

  function selectAllComponents() {
    const selection = {};
    Object.keys(cachedData.componentNameToPageIdComponentId).forEach((componentName) => {
      selection[componentName] = true;
    });
    setShouldShowSelectAllComponents(false);
    setComponentCheckboxSelected(selection);
  }

  function unselectAllComponents() {
    const selection = {};
    Object.keys(cachedData.componentNameToPageIdComponentId).forEach((componentName) => {
      selection[componentName] = false;
    });
    setShouldShowSelectAllComponents(true);
    setComponentCheckboxSelected(selection);
  }

  function togglePageCheckboxSelected(id) {
    setPageCheckboxSelected({
      ...pageCheckboxSelected,
      [id]: id in pageCheckboxSelected ? !pageCheckboxSelected[id] : true
    });
    return pageCheckboxSelected[id];
  }

  function selectAllPages() {
    const selection = {};
    Object.keys(cachedData.pageIdToPage).forEach((pageId) => {
      selection[pageId] = true;
    });
    setShouldShowSelectAllPages(false);
    setPageCheckboxSelected(selection);
  }

  function unselectAllPages() {
    const selection = {};
    Object.keys(cachedData.pageIdToPage).forEach((pageId) => {
      selection[pageId] = false;
    });
    setShouldShowSelectAllPages(true);
    setPageCheckboxSelected(selection);
  }

  async function updateIncident() {
    if (formState.incidentName === '') {
      alert('Error: Incident name cannot be empty.');
      return;
    }

    if (formState.incidentStatus === '') {
      alert('Error: Incident status cannot be empty.');
      return;
    }

    if (formState.incidentBody === '') {
      alert('Error: Incident body cannot be empty');
      return;
    }

    if (typeof formState.componentNameToStatus === 'undefined') {
      alert('Error: component status is undefined');
      return;
    }

    if (typeof internalId === 'undefined') {
      alert('Error: internalid is undefined');
      return;
    }

    if (!('internalIdToIncidentIds' in cachedData) || !(internalId in cachedData.internalIdToIncidentIds)) {
      alert('Error: No incidents found with this internal id');
      return;
    }

    setDataLoading(true);

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    const affectedPageIds = Object.keys(cachedData.pageIdToPage).filter((pageId) => pageCheckboxSelected[pageId]);

    // console.log('affected page ids:');
    // console.log(affectedPageIds);

    // const affectedComponentIdToStatus = {}
    const affectedComponentNames = Object.keys(cachedData.componentNameToPageIdComponentId).filter((componentName) => componentCheckboxSelected[componentName]);

    // console.log('affected component names');
    // console.log(affectedComponentNames);

    const affectedPageIdToComponentIds = {};
    affectedComponentNames.forEach((componentName) => {
      Object.keys(cachedData.componentNameToPageIdComponentId[componentName]).forEach((pageId) => {
        // console.log('pageid: ' + pageId);

        if (affectedPageIds.includes(pageId)) {
          if (!(pageId in affectedPageIdToComponentIds)) {
            affectedPageIdToComponentIds[pageId] = {};
          }

          const componentIdForPage = cachedData.componentNameToPageIdComponentId[componentName][pageId];
          affectedPageIdToComponentIds[pageId][componentIdForPage] = formState.componentNameToStatus[componentName];
        } else {
          // console.log('not added:' + pageId + '-' + componentName);
        }
      });
    });

    // console.log('affected pageid to component ids:')
    // console.log(affectedPageIdToComponentIds);

    const affectedIncidentIds = cachedData.internalIdToIncidentIds[internalId].filter((incidentId) => affectedPageIds.includes(cachedData.incidentIdToPageId[incidentId]));

    setInput('loadState', '[Phase 0] Updating incidents...');

    const requests = affectedIncidentIds.map((incidentId, index) =>
      new Promise((resolve) => {
        setTimeout(resolve, index * delayBetweenApiCalls);
      })
        .then(() =>
          fetch(`${apiUrl}/v1/pages/${cachedData.incidentIdToPageId[incidentId]}/incidents/${incidentId}`, {
            method: 'PUT',
            headers: {
              authorization: token,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              incident: {
                name: formState.incidentName,
                body: formState.incidentBody,
                status: formState.incidentStatus,
                components: affectedPageIdToComponentIds[cachedData.incidentIdToPageId[incidentId]],
                component_ids: Object.keys(affectedPageIdToComponentIds[cachedData.incidentIdToPageId[incidentId]]),
                metadata: {
                  id: {
                    value: internalId
                  },
                  email: {
                    value: user.attributes.email
                  }
                }
              }
            })
          })
        )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          console.log('Failed: with response');
          console.log(response);
          console.log('^^^^^^^^^^');
          throw new Error(response.status);
        })
    );

    Promise.all(requests).then((response) => {
      if (response.length > 0 && 'error' in response[0] && response[0].error.length > 0) {
        console.log('Failed with error message: ');
        console.log(response[0].error[0]);
        console.log('^^^^^^^^^^');
        alert(response[0].error[0]);
      }

      console.log(response);
      setInput('loadState', '[Phase 0] Incidents are updated.');
      setUpdateResult(response);
      setShouldLoadData(true);
    });
  }

  const applyTemplate = (event) => {
    if (event.target.value === 0) {
      return;
    }

    setInput('templateName', event.target.value);
    const template = cachedData.incidentTemplateNameToIncidentTemplate[event.target.value];
    setInput('incidentName', template.title);
    setInput('incidentBody', template.body);
    setInput('incidentStatus', template.update_status);

    const { componentNameToStatus } = formState;
    Object.keys(componentNameToStatus).forEach((componentName) => {
      componentNameToStatus[componentName] = 'operational';
    });
    template.components.forEach((component) => {
      componentNameToStatus[component.name] = component.status;
    });
    setInput('componentNameToStatus', componentNameToStatus);
  };

  function getStatusCard(intId) {
    const { status } = cachedData.incidentIdToIncident[cachedData.internalIdToIncidentIds[intId][0]];
    switch (status) {
      case 'investigating':
        return <span className="investigatingStatusCardStatus">{status}</span>;
      case 'identified':
        return <span className="identifiedStatusCardStatus">{status}</span>;
      case 'monitoring':
        return <span className="monitoringStatusCardStatus">{status}</span>;
      default:
        return <span className="resolvedStatusCardStatus">{status}</span>;
    }
  }

  useEffect(() => {
    if (shouldLoadData) {
      loadData();
      setShouldLoadData(false);
    }
  });

  if (!dataLoading && (typeof cachedData.internalIdToIncidentIds === 'undefined' || !(internalId in cachedData.internalIdToIncidentIds))) {
    return (
      <span style={{ fontSize: '50px', color: 'black' }}>
        Resolved.
        <div>
          <Link to="/incidents/create" style={{ marginTop: '50px' }} className="btn btn-primary">
            Go back to incidents page
          </Link>
        </div>
      </span>
    );
  }

  return (
    <div>
      {dataLoading ? (
        <div
          style={{
            fontSize: '25px',
            width: '800px',
            margin: '0 auto',
            background: '#000',
            color: '#fff'
          }}
        >
          {formState.loadState}
        </div>
      ) : (
        ''
      )}

      {dataLoading ? (
        <div style={{ zIndex: 20 }}>
          <img src={Logox} alt="loading..." className="loadingImage" />
          <div className="overlay" />
        </div>
      ) : (
        <div />
      )}

      <div>
        <Form>
          <Button onClick={dumpData} style={{ display: 'none' }}>
            Dump Data
          </Button>
        </Form>
      </div>

      <div>
        {'internalIdToIncidentIds' in cachedData && internalId in cachedData.internalIdToIncidentIds ? (
          <div key={internalId} className="iframe-replace">
            <div className="frame-wrapper">
              {getStatusCard(internalId)}
              <Logo style={{ marginLeft: '10px', marginTop: '10px' }} />
              <div className="frame-content">
                <span className="incident-title">
                  <p>
                    {cachedData.incidentIdToIncident[cachedData.internalIdToIncidentIds[internalId][0]].name.substring(0, 120) +
                      (cachedData.incidentIdToIncident[cachedData.internalIdToIncidentIds[internalId][0]].name.length > 120 ? '...' : '')}
                  </p>
                </span>

                <div className="incident-item incident-context">
                  Last updated {Math.floor((new Date() - new Date(cachedData.incidentIdToIncident[cachedData.internalIdToIncidentIds[internalId][0]].updated_at)) / 1000 / 60)} minutes ago
                </div>

                <div>
                  <label htmlFor="incident-link"> View Latest Updates: </label>
                  <div className="incident-item view-link">
                    {cachedData.internalIdToIncidentIds[internalId].map((incidentId) => (
                      <label key={incidentId} style={{ marginLeft: '4px', display: 'block' }}>
                        <span>- Incident: </span>
                        <a id="incident-link" href={cachedData.incidentIdToIncident[incidentId].shortlink} target="_blank" rel="noreferrer">
                          [{cachedData.incidentIdToIncident[incidentId].shortlink}]
                        </a>
                        <span style={{ marginLeft: '10px', marginRight: '10px' }}>on Page:</span>
                        <a href={`https://manage.statuspage.io/pages/${cachedData.incidentIdToPageId[incidentId]}`} target="_blank" rel="noreferrer">
                          {cachedData.pageIdToPage[cachedData.incidentIdToPageId[incidentId]].name}
                        </a>
                      </label>
                    ))}
                  </div>
                </div>

                <div className="updateButtonWrapperDiv">
                  <Link to="/incidents/create" className="btn btn-primary">
                    Go back to incidents page
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Loading</div>
        )}
      </div>
      {updateResult.length > 0 ? (
        <div>
          <Button
            onClick={() => {
              setUpdateResult({});
            }}
            variant="primary"
            id="hideButton"
          >
            Hide the Result To Continue
          </Button>

          <h2>Update Result</h2>

          <div>
            <ul>
              {updateResult.map((incident) => (
                <li key={incident.id}>
                  Incident Name: {incident.name} <br />
                  Incident Id: {incident.id} <br />
                  Page Id: {incident.page_id} <br />
                  Short Link:{' '}
                  <a href={incident.shortlink} target="_blank" rel="noreferrer">
                    {incident.shortlink}
                  </a>{' '}
                  <br />
                  Status: {incident.status} <br />
                  InternalId: {incident.metadata && incident.metadata.id && incident.metadata.id.value ? incident.metadata.id.value : ''}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <h1>Update incident</h1>
          <div>
            {'incidentTemplateNameToIncidentTemplate' in cachedData ? (
              <Form.Group>
                <Form.Label>Apply Template</Form.Label>
                <Form.Control as="select" onChange={applyTemplate} type="" placeholder="">
                  <option value="0">Choose...</option>
                  {Object.keys(cachedData.incidentTemplateNameToIncidentTemplate).map((incidentTemplateName) => (
                    <option key={incidentTemplateName} value={incidentTemplateName}>
                      {incidentTemplateName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            ) : (
              <div>Loading</div>
            )}
          </div>
          <div>
            <Form.Group>
              <Form.Label>Incident Name</Form.Label>
              <Form.Control onChange={(event) => setInput('incidentName', event.target.value)} value={formState.incidentName} type="" placeholder="Incident Name" />
            </Form.Group>
          </div>
          <div>
            <Form.Group>
              <Form.Label>Incident Status</Form.Label>
              <Form.Control as="select" value={formState.incidentStatus || 'investigating'} onChange={(event) => setInput('incidentStatus', event.target.value)} type="" placeholder="">
                <option>investigating</option>
                <option>identified</option>
                <option>monitoring</option>
                <option>resolved</option>
              </Form.Control>
            </Form.Group>
          </div>

          <div>
            <label htmlFor="incidentBody">Message</label>
            <ContentEditable
              id="incidentBody"
              className="editable"
              html={formState.incidentBody} // innerHTML of the editable div
              disabled={false} // use true to disable edition
              onChange={(event) => {
                setInput('incidentBody', event.target.value);
              }}
            />
          </div>

          <div>
            <h6>
              Components affected (
              <span>
                {shouldShowSelectAllComponents ? (
                  <a href="#!" onClick={selectAllComponents}>
                    Select all
                  </a>
                ) : (
                  <a href="#!" onClick={unselectAllComponents}>
                    Select none
                  </a>
                )}
              </span>
              )
            </h6>
            <hr />
            <div>
              {'componentNameToPageIdComponentId' in cachedData ? (
                Object.keys(cachedData.componentNameToPageIdComponentId).map((componentName) => (
                  <div className="checkbox" key={`checkbox_${componentName}`}>
                    {componentName in cachedData.componentNamesNotInAllPages ? (
                      <input type="checkbox" id={componentName} checked={false} disabled />
                    ) : (
                      <input
                        type="checkbox"
                        id={componentName}
                        onChange={() => toggleComponentCheckboxSelected(componentName)}
                        checked={componentName in componentCheckboxSelected ? componentCheckboxSelected[componentName] : toggleComponentCheckboxSelected(componentName)}
                      />
                    )}
                    <label className="itemName" htmlFor={componentName}>
                      {componentName}
                    </label>
                    <span>
                      {componentName in cachedData.componentNamesNotInAllPages ? (
                        <span className="warningIcon">
                          {' '}
                          Warning
                          <span className="warningMessage">This component is not defined in all pages.</span>
                        </span>
                      ) : (
                        ''
                      )}
                    </span>

                    <div className="component_status">
                      {componentName in cachedData.componentNamesNotInAllPages ? (
                        <Form.Control
                          as="select"
                          value={formState.componentNameToStatus && formState.componentNameToStatus[componentName] ? formState.componentNameToStatus[componentName] : 'operational'}
                          onChange={(event) => setComponentStatus(componentName, event.target.value)}
                          type=""
                          placeholder=""
                          disabled
                        >
                          <option value="operational">Operational</option>
                          <option value="degraded_performance">Degraded performance</option>
                          <option value="partial_outage">Partial outage</option>
                          <option value="major_outage">Major outage</option>
                          <option value="under_maintenance">Under maintenance</option>
                        </Form.Control>
                      ) : (
                        <Form.Control
                          as="select"
                          value={formState.componentNameToStatus && formState.componentNameToStatus[componentName] ? formState.componentNameToStatus[componentName] : 'operational'}
                          onChange={(event) => setComponentStatus(componentName, event.target.value)}
                          type=""
                          placeholder=""
                        >
                          <option value="operational">Operational</option>
                          <option value="degraded_performance">Degraded performance</option>
                          <option value="partial_outage">Partial outage</option>
                          <option value="major_outage">Major outage</option>
                          <option value="under_maintenance">Under maintenance</option>
                        </Form.Control>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div>Loading</div>
              )}
            </div>
          </div>

          <div>
            <h6>
              Pages affected (
              <span>
                {shouldShowSelectAllPages ? (
                  <a href="#!" onClick={selectAllPages}>
                    Select all
                  </a>
                ) : (
                  <a href="#!" onClick={unselectAllPages}>
                    Select none
                  </a>
                )}
              </span>
              )
            </h6>
            <hr />
            <div>
              {'pageIdToPage' in cachedData && 'internalIdToIncidentIds' in cachedData ? (
                Object.keys(cachedData.pageIdToPage).map((pageId) =>
                  cachedData.internalIdToIncidentIds[internalId].map((incidentId) => cachedData.incidentIdToPageId[incidentId]).includes(pageId) ? (
                    <div className="checkbox" key={`checkbox_${pageId}`}>
                      <input
                        type="checkbox"
                        id={pageId}
                        onChange={() => togglePageCheckboxSelected(pageId)}
                        checked={pageId in pageCheckboxSelected ? pageCheckboxSelected[pageId] : togglePageCheckboxSelected(pageId)}
                      />
                      <label className="itemName" htmlFor={pageId}>
                        <a href={`https://manage.statuspage.io/pages/${pageId}`} target="_blank" rel="noreferrer">
                          {cachedData.pageIdToPage[pageId].name}
                        </a>
                      </label>
                    </div>
                  ) : (
                    <div className="checkbox" key={`checkbox_${pageId}`}>
                      <input type="checkbox" id={pageId} checked={false} />
                      <label className="itemName" htmlFor={pageId}>
                        <a href={`https://manage.statuspage.io/pages/${pageId}`} target="_blank" rel="noreferrer">
                          {cachedData.pageIdToPage[pageId].name}
                        </a>
                      </label>
                      <span className="warningIcon">
                        {' '}
                        Warning
                        <span className="warningMessage">This page has no incident associated with this internalid.</span>
                      </span>
                    </div>
                  )
                )
              ) : (
                <div>Loading</div>
              )}
            </div>
          </div>

          <Button
            onClick={(event) => {
              updateIncident(event);
              setShouldLoadData(true);
            }}
            variant="primary"
            id="updateButton"
          >
            Update
          </Button>
        </div>
      )}
    </div>
  );
}

export default UpdateIncident;
