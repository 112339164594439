import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import CreateIncident from './CreateIncident';
import UpdateIncident from './UpdateIncident';
import Settings from './Settings';

function IncidentManagement() {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1>Incident Management</h1>
      </div>

      <Switch>
        <Route exact path="/incidents">
          <Redirect to="/incidents/manage" />
        </Route>
        <Route path="/incidents/create" component={CreateIncident} />
        <Route path="/incidents/update/:internalId" component={UpdateIncident} />
        <Route path="/incidents/settings" component={Settings} />
      </Switch>
    </div>
  );
}

export default IncidentManagement;
