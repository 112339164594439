import React from 'react';
import { View } from '@aws-amplify/ui-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Reports from '../../pages/reports';
import Datadog from '../datadog/Datadog';
import QuotaBalance from '../quotaBalance/QuotaBalance';
import IncidentManagement from '../incidentManagement/IncidentManagement';
import MinimalNavBar from '../minimalNavBar';
import NavBar from '../navBar';
import Grid from '../common/Grid';
import useQuery from '../../utilities/useQuery';

import './styles.css';

interface RoutesProps {
  userEmail: string
}

export default function Routes({userEmail}: RoutesProps) {
  const query = useQuery();
  const fullScreen = React.useMemo(() => query.get('fullScreen') === 'true', [query]);
  return (
    <Grid>
      {fullScreen ? (
        <MinimalNavBar />
      ) : (
        <View columnSpan={3} className="stickyNavBar">
          <NavBar userEmail={userEmail}/>
        </View>
      )}
      <View columnSpan={fullScreen ? 12 : 9} className={fullScreen ? ['container-fullscreen'] : []}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/reports" />
          </Route>
          <Route path="/reports" component={Reports} />
          <Route path="/datadog" component={Datadog} />
          <Route path="/quota-balance" component={QuotaBalance} />
          <Route path="/incidents" component={IncidentManagement} />
        </Switch>
      </View>
    </Grid>
  );
}
