// import './CheckUrls.css';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import RunReport from './RunReport';
import Items from './Items';

function CheckUrls() {
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1>Quota Balance Checker</h1>
      </div>

      <Switch>
        <Route exact path="/quota-balance">
          <Redirect to="/quota-balance/runs" />
        </Route>
        <Route path="/quota-balance/runs" component={RunReport} />
        <Route path="/quota-balance/items" component={Items} />
      </Switch>
    </div>
  );
}

export default CheckUrls;
