import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@aws-amplify/ui-react";
import React, {useCallback, useContext, useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRepeat} from "@fortawesome/free-solid-svg-icons";
import {bytesToSize, Report, ReportCheck} from "../helpers";
import {DetailsFooter, DetailsModal} from "./styles";
import {dateFormatter} from "../../../../utilities/TutukaFormatter";
import {ConfigsContext} from "../../../../context/configs";

interface MultipleReportDetailModalProps {
  report: Report | undefined;
  getStatusIcon: (reportCheck: ReportCheck | undefined, margin: string) => any;
  resendReport: (report: ReportCheck | undefined) => Promise<string>;
}

export default function MultipleReportDetailModal({ report, getStatusIcon, resendReport }: MultipleReportDetailModalProps) {
  const { environmentVariables } = useContext(ConfigsContext);
  const [rerunWasExecuted, setRerunWasExecuted] = React.useState(false);
  const [rerunMessage, setRerunMessage] = React.useState("");

  const firstReportCheck = useMemo(() => report?.checks[0], [report]);

  const handleResendReport = useCallback(async (reportCheck: ReportCheck | undefined) => {
    setRerunWasExecuted(true);
    try {
      const message = await resendReport(reportCheck);
      setRerunMessage(message);
    } catch(e) {
      setRerunWasExecuted(false);
    }
  }, [resendReport]);

  return (
    <DetailsModal>
      <Table size="small" variation="bordered" highlightOnHover>
        <TableHead>
          <TableRow>
            <TableCell as="th">
              Status
            </TableCell>
            <TableCell as="th">
              Check date
            </TableCell>
            <TableCell as="th">
              Generated date
            </TableCell>
            <TableCell as="th">
              URL
            </TableCell>
            <TableCell as="th">
              Report Size
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {report?.checks.map((reportCheck) => (
            <TableRow key={reportCheck.reportUrl}>
              <TableCell>
                {getStatusIcon(reportCheck, "auto")}
              </TableCell>
              <TableCell>
                {reportCheck.checkDate && dateFormatter.format(new Date(reportCheck.checkDate)) || "-"}
              </TableCell>
              <TableCell>
                {reportCheck.generatedDate && dateFormatter.format(new Date(reportCheck.generatedDate)) || "-"}
              </TableCell>
              <TableCell>
                <a href={`${environmentVariables.VOUCHER_ENGINE_URL}downloads/${reportCheck.reportUrl}`} target="_blank" rel="noreferrer">
                  {reportCheck.reportUrl}
                </a>
              </TableCell>
              <TableCell>
                {reportCheck?.reportSize && bytesToSize(reportCheck?.reportSize) || "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>


      {environmentVariables.ENV === environmentVariables.PROD_ENV_NAME && (
        <DetailsFooter>
          <Button variation="primary" onClick={() => handleResendReport(firstReportCheck)} disabled={rerunWasExecuted}>
            <FontAwesomeIcon icon={faRepeat} spin={!rerunMessage && rerunWasExecuted} /> &nbsp; Regenerate report
          </Button>
          {rerunMessage && (
            <div>
              <em style={{ color: 'var(--gray)' }}>{rerunMessage}</em>
              <br />
              <br />
            </div>
          )}
          Scheduled task url <code>{firstReportCheck?.rerunTaskUrl}</code>
        </DetailsFooter>
      )}
    </DetailsModal>
  )
}
