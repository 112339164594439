import { MouseEventHandler } from 'react';

export interface MenuInterface {
  header?: {
    path?: string;
    label?: string;
  };
  path?: string;
  label?: string;
  action?: MouseEventHandler;
  link?: string;
  subitems?: {
    path: string;
    label: string;
    action?: MouseEventHandler;
    link?: string;
  }[];
}

export const menu: MenuInterface[] = [
  {
    path: '/datadog',
    label: 'Datadog'
  },
  {
    header: {
      label: 'Reports'
    },
    subitems: [
      {
        path: '/reports/status',
        label: 'Status'
      },
      {
        path: '/reports/campaigns',
        label: 'Campaigns'
      },
      {
        path: '/reports/campaignReports',
        label: 'Campaign Reports'
      }
    ]
  },
  {
    header: {
      label: 'Quota Balance'
    },
    subitems: [
      {
        path: '/quota-balance/runs',
        label: 'History'
      },
      {
        path: '/quota-balance/items',
        label: 'Manage Items'
      }
    ]
    /* }, {
    header: {
      label: 'Incident Management',
    },
    subitems: [{
      path: '/incident-management/create',
      label: 'New Incident',
    }, {
      path: '/incident-management/settings',
      label: 'Incident Settings',
    }]
  }, {
    header: {
      label: 'Debug / Tools',
    },
    subitems: [{
      action: hardReloadData,
      label: 'Hard Refresh',
    }] */
  }
];
