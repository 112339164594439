// import './Settings.css';

import React, { ChangeEvent, useState } from 'react';

import { Button, Form } from 'react-bootstrap';

const initialState = {
  delayBetweenCalls: localStorage.getItem('delayBetweenCalls') ? `${localStorage.getItem('delayBetweenCalls')}` : 500
};

function Settings() {
  const [formState, setFormState] = useState(initialState);

  function setInput(key: string, value: string) {
    setFormState((fState) => ({ ...fState, [key]: value }));
  }

  const Save = () => {
    console.log(formState.delayBetweenCalls);
    console.log(typeof formState.delayBetweenCalls);
    if (typeof formState.delayBetweenCalls !== 'string' || Number.isNaN(formState.delayBetweenCalls)) {
      alert(`Invalid value for the delay between API calls${formState.delayBetweenCalls}`);
      return;
    }

    localStorage.setItem('delayBetweenCalls', formState.delayBetweenCalls);
  };

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Label>Delay in milliseconds between API calls to prevent being rate limited</Form.Label>
          <Form.Control
            onChange={(event: ChangeEvent<HTMLInputElement>) => setInput('delayBetweenCalls', event.target.value)}
            value={formState.delayBetweenCalls}
            type=""
            placeholder="500"
          />
        </Form.Group>
        <Button onClick={Save} variant="primary">
          Save
        </Button>
      </Form>
    </div>
  );
}

export default Settings;
