import {Image, Button} from "react-bootstrap";
import {Auth} from "aws-amplify";
import logo from "../../logo.png";

interface FederatedSignInProps {
  providerName: string
}

function FederatedSignIn({providerName}: FederatedSignInProps) {
  return (
    <div data-amplify-authenticator="" data-variation="default">
      <div data-amplify-container="">
        <div style={{textAlign:"center", padding:"5px"}}>
        <Image className="amplify-image" alt="CD Dashboard Logo" src={logo} style={{height: "50%", width: "50%"}} />
        </div>
        <div data-amplify-router="" style={{borderTop: "0px"}}>
        <div data-orientation="horizontal">
          <div role="tablist" aria-orientation="horizontal" dir="ltr" tabIndex={0} data-orientation="horizontal" style={{borderStyle: "solid", borderTop:"5px solid hsl(190deg 95% 30%)", borderBottom:"0px", borderLeft:"0px", borderRight:"0px", }}>
            <h6 style={{color: "hsl(190deg 95% 30%)", borderColor: "hsl(190deg 95% 30%)", backgroundColor: "#00000000", padding:"0.75rem 1rem", marginBottom:"-2px", textAlign:"center"}}><b>Sign In</b>
            </h6>
          </div>
          <div data-state="active">
            <div data-amplify-router-content="">
              <div>
                <p style={{padding:"0.4rem 1.4rem"}}>Welcome to the Customer Delight Dashboard. You can sign in using your corporate Microsoft Account.</p>
              </div>
              <div style={{padding:"1.2rem"}}>
                <Button variant="primary" className="amplify-button amplify-field-group__control amplify-button--primary amplify-button--fullwidth" onClick={() => Auth.federatedSignIn({customProvider: providerName})} style={{backgroundColor: "hsl(190deg 95% 30%)"}}>Sign In with your Microsoft Account</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default FederatedSignIn;
