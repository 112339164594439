import {useCallback, useState} from 'react';

export const LOCAL_STORAGE_KEYS = {
  IS_DATASTORE_READY: 'IS_DATASTORE_READY',
  ENVIRONMENT_VARIABLES: 'ENVIRONMENT_VARIABLES',
  USER: 'USER'
};

function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback((value: T | ((val: any) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);

    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    }
  }, [storedValue, setStoredValue]);

  return [storedValue, setValue] as const;
}

export default useLocalStorage;
