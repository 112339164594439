import React, { useCallback, useContext } from 'react';

import { API, graphqlOperation } from 'aws-amplify';
import { Report, ReportCheck } from '../helpers';
import { ConfigsContext } from '../../../../context/configs';
import { addReportRerunQueue } from '../../../../graphql/queries';
import SingleReportDetailModal from './single-report-detail-modal';
import MultipleReportDetailModal from './multiple-report-detail-modal';

export default function ReportDetailModal({ selectedReport, getReportCheckStatusIcon }: { selectedReport: Report | undefined; getReportCheckStatusIcon: (reportCheck: ReportCheck | undefined, marginUnset: string) => any }) {
  const { userEmail } = useContext(ConfigsContext);

  const resendReport = useCallback(
    async (report: ReportCheck | undefined) => {
      const {
        data: { addReportRerunQueue: response }
      } = (await API.graphql(
        graphqlOperation(addReportRerunQueue, {
          reportUrl: report?.rerunTaskUrl,
          slackEmail: userEmail
        })
      )) as any;

      if (response.statusCode === 200) {
        return 'Report regeneration was successfully queued.';
      }

      return 'There was an error while queuing the report regeneration. Please try again later.';
    },
    [selectedReport, userEmail]
  );

  return (
    selectedReport?.checks.length === 1 && (
      <SingleReportDetailModal report={selectedReport} getStatusIcon={getReportCheckStatusIcon} resendReport={resendReport} />
    ) || (
      <MultipleReportDetailModal report={selectedReport} getStatusIcon={getReportCheckStatusIcon} resendReport={resendReport} />
    )
  );

}
