import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import isAmplifyEnvWithSSO from '../utilities/isAmplifyEnvWithSSO';

const OWNER_GROUPS: { [key: string]: string } = {
  maindev: '029b3e94-7fb3-46ef-b471-5a0bacefe355',
  mainuat: 'c7154084-b7ff-4fe7-b7e6-f80577d36ed8',
  mainprod: '87631c71-dc9e-41d2-b65d-6847931c0c83'
};

const DEFAULT_ENV = 'maindev';

export default function useIsOwner() {
  const [isOwner, setIsOwner] = useState<Boolean>(false);
  const [auth, setAuth] = useState<any>(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((a) => {
      setAuth(a);
    });
  }, []);

  useEffect(() => {
    if (!auth) {
      setIsOwner(false);
      return;
    }
    if (!isAmplifyEnvWithSSO()) {
      setIsOwner(true); // local dev
      return;
    }
    const env = auth?.storage?.ENVIRONMENT_VARIABLES?.ENV || DEFAULT_ENV;
    const ownerGroup = OWNER_GROUPS[env];
    if (auth && ownerGroup) {
      const groups = auth.signInUserSession.idToken.payload['custom:azure_groups'];
      if (groups && groups.indexOf(ownerGroup) >= 0 && !isOwner) {
        setIsOwner(true);
      } else if (isOwner) {
        setIsOwner(false);
      }
    }
  }, [auth]);

  return isOwner;
}
