// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ReportParameterType = {
  "STORED_PROCEDURE": "STORED_PROCEDURE",
  "FUNCTION": "FUNCTION",
  "CAMPAIGN_COLUMN": "CAMPAIGN_COLUMN"
};

const CheckType = {
  "CAMPAIGN": "CAMPAIGN",
  "URL": "URL",
  "QUOTA": "QUOTA"
};

const CheckItemStatus = {
  "SUCCESS": "SUCCESS",
  "NOT_FOUND": "NOT_FOUND",
  "ERROR": "ERROR",
  "NOT_CHECKED": "NOT_CHECKED"
};

const { QuotaRuns, Quotas, Campaigns, Reports, ReportParameters, Checks, CheckItems, CampaignReports, ReportMetadata, CampaignDto, EnvironmentVariables, GenericResponse, ClientDto, ReportDto } = initSchema(schema);

export {
  QuotaRuns,
  Quotas,
  Campaigns,
  Reports,
  ReportParameters,
  Checks,
  CheckItems,
  CampaignReports,
  ReportParameterType,
  CheckType,
  CheckItemStatus,
  ReportMetadata,
  CampaignDto,
  EnvironmentVariables,
  GenericResponse,
  ClientDto,
  ReportDto
};