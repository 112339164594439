import { Button } from '@aws-amplify/ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useContext } from "react";
import { bytesToSize, Report, ReportCheck } from "../helpers";
import { dateFormatter } from '../../../../utilities/TutukaFormatter';
import { DetailsContent, DetailsFooter, DetailsModal, DetailsTitle } from './styles';
import { ConfigsContext } from "../../../../context/configs";

interface SingleReportDetailModalProps {
  report: Report | undefined;
  getStatusIcon: (reportCheck: ReportCheck | undefined, margin: string) => any;
  resendReport: (report: ReportCheck | undefined) => Promise<string>;
}

export default function SingleReportDetailModal({ report, getStatusIcon, resendReport }: SingleReportDetailModalProps) {
  const [rerunMessage, setRerunMessage] = React.useState("");
  const [rerunWasExecuted, setRerunWasExecuted] = React.useState(false);
  const { environmentVariables } = useContext(ConfigsContext);

  const handleResendReport = useCallback(async (reportCheck: ReportCheck | undefined) => {
    setRerunWasExecuted(true);
    try {
      const message = await resendReport(reportCheck);
      setRerunMessage(message);
    } catch(e) {
      setRerunWasExecuted(false);
    }
  }, [resendReport]);

  const reportCheck = report?.checks[0];

  return (
    <DetailsModal>
      {(reportCheck?.checked && (
        <>
          <DetailsTitle>Status</DetailsTitle>
          {getStatusIcon(reportCheck, "unset")}
          <br />

          <DetailsTitle>Check date</DetailsTitle>
          <DetailsContent>{dateFormatter.format(new Date(reportCheck.checkDate))}</DetailsContent>
          <br />

          {reportCheck.reportUrl && (
            <>
              <DetailsTitle>Check URL</DetailsTitle>
              <DetailsContent>
                <a href={`${environmentVariables.VOUCHER_ENGINE_URL}downloads/${reportCheck.reportUrl}`} target="_blank" rel="noreferrer">
                  {reportCheck.reportUrl}
                </a>
              </DetailsContent>
              <br />
            </>
          )}

          {(reportCheck?.generatedDate || 0) > 0 && (
            <>
              <DetailsTitle>Generated date</DetailsTitle>
              <DetailsContent>{reportCheck.generatedDate && dateFormatter.format(new Date(reportCheck.generatedDate))}</DetailsContent>
              <br />
            </>
          )}

          {(reportCheck?.reportSize || 0) > 0 && (
            <>
              <DetailsTitle>Report size</DetailsTitle>
              <DetailsContent>{bytesToSize(reportCheck?.reportSize)}</DetailsContent>
            </>
          )}
        </>
      )) || <>Report not checked for this campaign</>}


      {environmentVariables.ENV === environmentVariables.PROD_ENV_NAME && (
        <DetailsFooter>
          <Button variation="primary" onClick={() => handleResendReport(reportCheck)} disabled={rerunWasExecuted}>
            <FontAwesomeIcon icon={faRepeat} spin={!rerunMessage && rerunWasExecuted} /> &nbsp; Regenerate report
          </Button>
          {rerunMessage && (
            <div>
              <em style={{ color: 'var(--gray)' }}>{rerunMessage}</em>
              <br />
              <br />
            </div>
          )}
          Scheduled task url <code>{reportCheck?.rerunTaskUrl}</code>
        </DetailsFooter>
      )}
    </DetailsModal>
  );
}
