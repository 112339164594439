import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .amplify-table {
    th {
      text-align: center;
    }
    td {
      text-align: center;
      cursor: pointer;
    }
  }

  .amplify-expander__header {
    background: #e4e4e4 !important;
  }

  .amplify-expander__header button {
    font-weight: bold;
  }

  .amplify-expander__item {
    border-bottom: 2px solid #eee !important;
  }

  .amplify-expander__content__text {
    overflow-x: auto;
  }
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
`;

export const ContainerHeader = styled.h3``;

export const Legend = styled.strong``;

const StatusCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 20px;
  
  color: white;
  font-weight: 800;

  svg {
    width: 13px !important;
    height: 13px !important;
  }
`;

export const SuccessStatus = styled(StatusCircle)`
  background: var(--green);
`;

export const NotCheckedStatus = styled(StatusCircle)`
  background: grey;
`;

export const FailedStatus = styled(StatusCircle)`
  background: var(--red);
`;

export const PartialSuccessStatus = styled(StatusCircle)`
  background: var(--blue);
`;

export const TableCellContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
