import React, { FormEvent, useCallback, useEffect, useState } from 'react';

import { Button, Loader, View } from '@aws-amplify/ui-react';
import { DataStore, Predicates, SortDirection } from 'aws-amplify';
import Grid from '../../../components/common/Grid';
import Typeahead from '../../../components/common/Typeahead';

import { getCampaignsByName } from '../../../graphql/queries';
import { CampaignDto } from '../../../API';
import useDebounceAPI from '../../../hooks/useDebounceAPI';

import CampaignsList from './list';
import { Campaigns as Campaign } from '../../../models';
import useIsOwner from '../../../hooks/useIsOwner';

function Campaigns() {
  const [campaign, setCampaign] = useState<CampaignDto | null>();
  const [savedCampaigns, setSavedCampaigns] = useState<Campaign[]>();
  const [name, setName] = useState<string>('');
  const [suggestions, setSuggestions] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);
  const [campaignsLoading, setCampaignsLoading] = useState<Boolean | false>();
  const isOwner = useIsOwner();

  const removeSavedCampaigns = useCallback(
    (queriedCampaigns: { label: string; value: CampaignDto }[]) => {
      const filtered = queriedCampaigns.filter((queriedCampaign) => !savedCampaigns?.some((savedCampaign) => savedCampaign.CampaignID === queriedCampaign.value.CampaignID));

      return filtered;
    },
    [savedCampaigns]
  );

  const onGetCampaignsByNameFinish = useCallback(
    async (promise: Promise<any>) => {
      const res = await promise;

      setCampaignsLoading(false);

      if (!res.data.getCampaignsByName) {
        return;
      }

      const queriedCampaigns = res.data.getCampaignsByName.map((item: CampaignDto) => ({ label: `${item.CampaignID} - ${item.CampaignName}`, value: item })) as { label: string; value: CampaignDto }[];

      const filteredCampaigns = removeSavedCampaigns(queriedCampaigns);

      setSuggestions(filteredCampaigns);
    },
    [setSuggestions, removeSavedCampaigns]
  );

  const debouncedGetCampaignsByName = useDebounceAPI(getCampaignsByName, onGetCampaignsByNameFinish);

  const fetchSuggestions = useCallback(
    async (value: string) => {
      if (!value) {
        return;
      }

      setCampaignsLoading(true);
      debouncedGetCampaignsByName({ name: value });
    },
    [debouncedGetCampaignsByName]
  );

  const onChange = useCallback(
    async (event: FormEvent<HTMLElement>) => {
      const element = event.currentTarget as HTMLInputElement;
      const { value } = element;
      setName(value);
    },
    [setName]
  );

  const onSelect = useCallback(
    (suggestion: { label: string; value: CampaignDto }) => {
      setCampaign(suggestion.value);
      setName(suggestion.label);
      setSuggestions([]);
    },
    [setCampaign, setName, setSuggestions]
  );

  const fetchSavedCampaigns = useCallback(async () => {
    setSavedCampaigns(
      await DataStore.query(Campaign, Predicates.ALL, {
        sort: (item) => item.CampaignName(SortDirection.ASCENDING)
      })
    );
  }, [setSavedCampaigns]);

  const onSubmit = useCallback(async () => {
    if (!campaign) {
      return;
    }

    await DataStore.save(new Campaign(campaign));

    setCampaign(null);
    setName('');

    await fetchSavedCampaigns();
  }, [setCampaign, campaign, setName]);

  useEffect(() => {
    fetchSavedCampaigns();
  }, []);

  return (
    <Grid>
      <View columnSpan={12}>
        <h3>Campaigns</h3>
      </View>

      <View columnSpan={12}>
        <Typeahead
          value={name}
          onChange={onChange}
          onFetch={fetchSuggestions}
          clearSuggestions={() => setSuggestions([])}
          placeholder="Search for campaigns here"
          suggestions={suggestions}
          onSelect={onSelect}
        />
        {campaignsLoading ? <Loader className="loader" size="large" /> : null}
      </View>

      {isOwner && (
        <View columnSpan={12}>
          <Button variation="primary" onClick={onSubmit}>
            Add Campaign
          </Button>
        </View>
      )}
      <View columnSpan={12}>
        <CampaignsList onDeleteCampaign={fetchSavedCampaigns} campaigns={savedCampaigns || []} />
      </View>
    </Grid>
  );
}

export default Campaigns;
