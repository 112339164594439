const dateFormatter = new Intl.DateTimeFormat('en-ZA', {
  dateStyle: 'short',
  timeStyle: 'medium',
  timeZone: 'Africa/Johannesburg'
});

const numberFormatter = new Intl.NumberFormat('en-ZA');

const currencyFormatter = new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR' });

export { currencyFormatter, dateFormatter, numberFormatter };
