import styled from 'styled-components';

const backdropZIndex = 100;

interface BackdropProps {
  isOpen: boolean;
}

export const Backdrop = styled.div<BackdropProps>`
  inset: 0;
  position: fixed;
  background: rgb(0 0 0 / 25%);
  z-index: ${backdropZIndex};
`;

interface ModalProps {
  size: 'sm' | 'md' | 'lg';
}

export const Modal = styled.div<ModalProps>`
  z-index: ${backdropZIndex + 1};
  background-color: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  border: 1px solid #e1e1e1;
  border-radius: 1rem;
  box-shadow: var(--amplify-shadows-large);
  inset: ${({ size }) => {
    switch (size) {
      case 'lg':
        return '10%';
      case 'md':
        return '20%';
      case 'sm':
        return '30%';
      default:
        return '30%';
    }
  }};
  overflow-y: auto;
  height: auto;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 15px;
  box-shadow: var(--amplify-shadows-small);
`;

export const ModalHeaderText = styled.h3`
  all: unset;
  flex: 1;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const ModalContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  overflow-x: auto;
`;
