import styled from "styled-components";

export const DetailsModal = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DetailsTitle = styled.strong``;

export const DetailsContent = styled.span`
  line-break: anywhere;
`;

export const DetailsFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;
