import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './styles.css';

export default function MinimalNavBar() {
  const history = useHistory();
  const location = useLocation();
  const exitFullScreen = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      history.push(`${location.pathname}?fullScreen=false`);
    },
    [history, location.pathname]
  );
  return (
    <aside className="fullscreen-sidebar">
      <nav className="sidebar-nav">
        <ul>
          <li>
            <a href="#go" onClick={exitFullScreen}>
              Exit Fullscreen
            </a>
          </li>
        </ul>
      </nav>
    </aside>
  );
}
