import React, { MouseEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { menu } from './menu';

import './styles.css';

interface NavBarProps {
  userEmail: string
}

export default function NavBar({userEmail}: NavBarProps) {
  const location = useLocation();
  async function handleSignOut(e: MouseEvent<HTMLAnchorElement>){
    e.preventDefault();
    await Auth.signOut();
  };

  return (
    <section className="app">
      <aside className="sidebar">
        <Link to="/">
          <header>CD Dashboard</header>
        </Link>
        <nav className="sidebar-nav">
          <ul>
            {menu.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>
                {item.header && item.header.path && (
                  <Link to={item.header.path}>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                      <span>{item.header.label}</span>
                    </h6>
                  </Link>
                )}
                {item.header && !item.header.path && (
                  <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>{item.header.label}</span>
                  </h6>
                )}
                <div className="smenu">
                  {item.path && <Link to={item.path}>{item.label}</Link>}
                  {item.action && (
                    <a onClick={item.action} href="#go">
                      {item.label}
                    </a>
                  )}
                  {item.subitems &&
                    item.subitems.map((child, subIndex) =>
                      child.path ? (
                        // eslint-disable-next-line react/no-array-index-key
                        <Link key={`${index}-${subIndex}`} to={child.path}>
                          {child.label}
                        </Link>
                      ) : (
                        // eslint-disable-next-line react/no-array-index-key
                        <a key={`${index}-${subIndex}`} onClick={child.action} href="#go">
                          {child.label}
                        </a>
                      )
                    )}
                </div>
              </li>
            ))}
            <li className="sidebar-nav-fullscreen">
              <Link to={`${location.pathname}?fullScreen=true`}>Enter Fullscreen</Link>
            </li>
            {userEmail && (
              <li className="sidebar-nav-email">
                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                  {userEmail}
                </h6>
              </li>
            )}
            (
              <li className="sidebar-nav-signout">
                <a onClick={async (e) => {await handleSignOut(e)}} href="/">
                  Sign Out
                </a>
              </li>
            )
          </ul>
        </nav>
      </aside>
    </section>
  );
}
