import './QuotaBalance.css';
import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faMinusSquare } from '@fortawesome/free-regular-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';

import { DataStore, SortDirection } from '@aws-amplify/datastore';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Table, TableBody, TableRow, TableCell, TableHead, Badge, Button, Loader } from '@aws-amplify/ui-react';
import { QuotaRuns, ClientDto } from '../../models';
import { currencyFormatter, dateFormatter } from '../../utilities/TutukaFormatter';
import PickClientByName from './PickClientByName';

function RunReport() {
  const [sortDir, setSortDir] = useState(SortDirection.DESCENDING);
  const [quotaRuns, setQuotaRuns] = useState<QuotaRuns[]>([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [filteredClient, setFilteredClient] = useState<ClientDto | null>(null);
  const pageSize = 10;

  const queryRunsHistory = useCallback(async () => {
    const pageNumber = quotaRuns.length / pageSize;
    const latest = new Date();
    latest.setDate(latest.getDate() - 15);

    let quotaRunsRead;

    if (filteredClient != null) {
      quotaRunsRead = await DataStore.query(QuotaRuns, (quotaRun) => quotaRun.and( q => [
          q.finishAt.gt(latest.getTime()),
          q.clientName.eq(filteredClient.ClientName!)
        ]), {
        sort: (run) => run.finishAt(sortDir),
        page: pageNumber,
        limit: pageSize
      });
    } else {
      quotaRunsRead = await DataStore.query(QuotaRuns, (quotaRun) => quotaRun.finishAt.gt(latest.getTime()), {
        sort: (run) => run.finishAt(sortDir),
        page: pageNumber,
        limit: pageSize
      });
    }



    if (quotaRunsRead.length > 0) {
      quotaRuns.push(...quotaRunsRead);

      setQuotaRuns(
        quotaRuns.concat(quotaRunsRead)
        );

    }
    
    if (quotaRunsRead.length < pageSize) {
      setHasMoreData(false);
    }

  }, [sortDir, filteredClient]);

  function resetTable() {
    setQuotaRuns([]);
    setHasMoreData(true);
  }

  const toggleSortDirection = () => {
    setSortDir(sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING);
    resetTable();
  };

  function decodeAndPresentBody(record: QuotaRuns) {
    try {
      const parsed = JSON.parse(record.responseBody!);
      if (typeof parsed === 'undefined' || typeof parsed.CurrentBalance === 'undefined') {
        return (
          <p>
            Balance check empty:
            <code>{record.responseBody}</code>
          </p>
        );
      }
      return (
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell scope="row">Current Balance</TableCell>
              <TableCell className="currency">{currencyFormatter.format(parsed.CurrentBalance)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Limit</TableCell>
              <TableCell className="currency">{currencyFormatter.format(record.balanceLimit as number)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    } catch (jsonError) {
      return (
        <p>
          Balance check not completed. Response Body: &nbsp;
          <code>{record.responseBody}</code>
        </p>
      );
    }
  }

  const onClientChanged = (client: ClientDto | null) => {
    setFilteredClient(client);
    resetTable();
  }


  useEffect(() => {

    if (quotaRuns.length === 0 && hasMoreData) { // Load initial dataset
      queryRunsHistory();
    }

  }, [queryRunsHistory, quotaRuns]);

  return (
    <>
        <h2>Latest Checks</h2>
        <PickClientByName onClientChanged={onClientChanged}/>
        <InfiniteScroll
          hasMore={hasMoreData}
          next={queryRunsHistory}
          loader={<Loader variation="linear"/>}
          dataLength={quotaRuns.length}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>- End -</b>
            </p>
          }
        >

          <Table id="runs-history" variation="striped" highlightOnHover>
            <TableHead>
              <TableRow>
                <TableCell as="th">Client ID</TableCell>
                <TableCell as="th">Client Name</TableCell>
                <TableCell as="th">Balance</TableCell>
                <TableCell as="th">Status</TableCell>
                <TableCell as="th" className='sortField'>
                  Checked At
                  <Button className='sortField' size="small" onClick={toggleSortDirection}>
                    <FontAwesomeIcon icon={sortDir === SortDirection.ASCENDING ? faCaretUp : faCaretDown} />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotaRuns.map((record) => (
                <TableRow key={record.id}>
                  <TableCell>
                    <code>{record.clientId}</code>
                  </TableCell>
                  <TableCell>
                    <code>{record.clientName}</code>
                  </TableCell>
                  <TableCell>{decodeAndPresentBody(record)}</TableCell>
                  <TableCell>
                    <Badge variation={record.zendeskTicketId! <= 0 ? 'success' : 'warning'}>
                      <FontAwesomeIcon icon={record.zendeskTicketId! <= 0 ? faCheckCircle : faMinusSquare} />
                    </Badge>
                    {record.zendeskTicketId! > 0 && (
                      <a href={`https://tutuka.zendesk.com/agent/tickets/${record.zendeskTicketId}`}>
                        #{record.zendeskTicketId}
                      </a>
                    )}
                  </TableCell>
                  <TableCell>{dateFormatter.format(new Date(record.finishAt!))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
    </>
  );
}

export default RunReport;
