import React, { useCallback, useEffect, useState } from 'react';

import { Button, Expander, ExpanderItem, SearchField, Table, TableBody, TableCell, TableHead, TableRow, View } from '@aws-amplify/ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import { API, graphqlOperation } from 'aws-amplify';
import Modal from '../../../components/modal';
import { CheckItemStatus } from '../../../models';
import { Container, ContainerHeader, FailedStatus, Legend, LegendContainer, PartialSuccessStatus, SubtitleContainer, SuccessStatus, TableCellContent } from './styles';

import { dateFormatter } from '../../../utilities/TutukaFormatter';
import { CheckDto, fetchCheckItems, Report, ReportCheck } from './helpers';
import Spinner from '../../../components/common/Spinner';
import ReportDetailModal from './report-detail-modal';
import { checkCampaigns } from '../../../graphql/queries';
import useDebounceAPI from '../../../hooks/useDebounceAPI';
import { listChecks } from './queries';
import { CheckType, ModelSortDirection } from '../../../API';

export default function ReportList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<{ campaignName: string; campaignId: string; campaignEntityUuid: string }>();
  const [selectedReport, setSelectedReport] = useState<Report>();
  const [checks, setChecks] = useState<CheckDto[]>([]);
  const [isLoadingChecks, setIsLoadingChecks] = useState(true);
  const [isLoadingCheckItems, setIsLoadingCheckItems] = useState(false);
  const [isExecutingChecks, setIsExecutingChecks] = useState(false);
  const [filterByCampaign, setFilterByCampaign] = useState<{ id: string; filter: string }[]>([]);

  const handleFetchChecks = useCallback(
    async (response: any) => {
      setIsLoadingChecks(true);
      const checksRetrieved = ((await response) || []).data.checksByType.items;
      setChecks(checksRetrieved);
      setIsLoadingChecks(false);
    },
    [setIsLoadingChecks, setChecks]
  );

  const debouncedFetchChecks = useDebounceAPI(listChecks, handleFetchChecks);

  const fetchChecks = useCallback(async () => {
    debouncedFetchChecks({ limit: 14, sortDirection: ModelSortDirection.DESC, Type: CheckType.CAMPAIGN });
  }, [debouncedFetchChecks]);

  useEffect(() => {
    fetchChecks();
  }, [fetchChecks]);

  const openModal = useCallback(async ({ campaign, report }: { campaign: { campaignName: string; campaignId: string; campaignEntityUuid: string }; report?: Report }) => {
    setIsModalOpen(true);
    setSelectedCampaign(campaign);
    setSelectedReport(report);
  }, []);

  const executeCheckCampaigns = useCallback(async () => {
    setIsExecutingChecks(true);

    try {
      (await API.graphql(graphqlOperation(checkCampaigns))) as Promise<any>;
      await fetchChecks();
    } catch (e) {
      console.error(e);
    }

    setIsExecutingChecks(false);
  }, [setIsExecutingChecks]);

  const getReportCheckStatusIcon = useCallback((reportCheck: ReportCheck | undefined, margin = '') => {
    if (reportCheck?.status === CheckItemStatus.SUCCESS) {
      return <SuccessStatus style={{ margin }} />;
    }

    if (reportCheck?.status === CheckItemStatus.NOT_FOUND || reportCheck?.status === CheckItemStatus.ERROR) {
      return <FailedStatus style={{ margin }} />;
    }

    return null;
  }, []);

  const getReportStatusIcon = useCallback((report: Report, margin = '') => {
    if (report.checks.length <= 1) {
      const reportCheck = report.checks[0];

      return getReportCheckStatusIcon(reportCheck, margin);
    }

    if (report.isSuccessful()) {
      return <SuccessStatus style={{ margin }}> {report.checks.length} </SuccessStatus>;
    }

    if (report.isFailed()) {
      return <FailedStatus style={{ margin }}> {report.checks.length} </FailedStatus>;
    }

    if (report.isPartiallySuccessful()) {
      return <PartialSuccessStatus style={{ margin }}> {report.checks.length} </PartialSuccessStatus>;
    }

    return report.checks.length;
  }, []);

  const mayBeFilteredByCampaign = (checkId: string, campaignId: string, campaignName: string) => {
    const filterState = filterByCampaign.filter((o) => o.id === checkId)[0];
    const check = !filterState || campaignName.toLowerCase().includes(filterState.filter.toLowerCase()) || campaignId.toLowerCase().includes(filterState.filter.toLowerCase());
    return check;
  };

  const onClickExpanderItem = useCallback(async (check: CheckDto) => {
    if (check.CheckItems) {
      return;
    }

    setIsLoadingCheckItems(true);

    await fetchCheckItems(check);

    try {
      setChecks((actual) => {
        const newArray = [...actual];

        const index = actual.indexOf(check);

        newArray[index] = check;

        return newArray;
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingCheckItems(false);
    }
  }, []);

  const getEmptyCheckItemsJsx = useCallback((isLoading: boolean) => (isLoading ? <Spinner style={{ margin: 'auto' }} /> : <p>No checks executed</p>), []);

  return (
    <Container>
      <SubtitleContainer>
        <ContainerHeader>Status</ContainerHeader>

        <Legend>Legend</Legend>
        <LegendContainer>
          <SuccessStatus style={{ margin: 'unset' }} />
          &nbsp;- Success
        </LegendContainer>
        <LegendContainer>
          <PartialSuccessStatus style={{ margin: 'unset' }} />
          &nbsp;- Partial Success
        </LegendContainer>
        <LegendContainer>
          <FailedStatus style={{ margin: 'unset' }} />
          &nbsp;- Failed
        </LegendContainer>
        {/*
            Commented because the feature is not implemented yet

            <LegendContainer>
                <FontAwesomeIcon style={{ height: '26px', width: '26px' }} icon={faStar} />
                &nbsp;- Important check
            </LegendContainer>
        */}
      </SubtitleContainer>
      <br />

      <Button variation="primary" onClick={executeCheckCampaigns} disabled={isExecutingChecks} width="13rem">
        <FontAwesomeIcon icon={faRepeat} spin={isExecutingChecks} /> &nbsp; Execute checks
      </Button>

      <br />

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="lg" header={`${selectedCampaign?.campaignName} - ${selectedReport?.name || selectedReport}`}>
        <ReportDetailModal selectedReport={selectedReport} getReportCheckStatusIcon={getReportCheckStatusIcon} />
      </Modal>

      {isLoadingChecks ? (
        <Spinner />
      ) : (
        <Expander type="multiple">
          {checks.map((check) => {
            const orderedCampaigns = check.campaigns ? check.campaigns.slice() : [];
            orderedCampaigns.sort((a, b) => (a.campaignName > b.campaignName ? 1 : -1));

            return (
              <ExpanderItem key={check.id} value={check.id} title={dateFormatter.format(check.Date)} onClick={() => onClickExpanderItem(check)}>
                {orderedCampaigns.length > 0 ? (
                  <View>
                    <View width="20rem" marginBottom="1rem">
                      <SearchField
                        label="Campaigns Filter"
                        placeholder="Filter by campaigns"
                        hasSearchButton={false}
                        hasSearchIcon
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setFilterByCampaign((filters) => {
                            const filterArr = filters?.filter((o) => o.id === check.id);
                            if (filterArr?.length === 0) {
                              filters.push({ id: check.id, filter: event.target.value });
                              return filters;
                            }
                            return filters.map((o) => {
                              if (o.id === check.id) {
                                return { id: check.id, filter: event.target.value };
                              }
                              return o;
                            });
                          });
                        }}
                        onClear={() => {
                          setFilterByCampaign((filters) => filters?.filter((o) => o.id !== check.id));
                        }}
                      />
                    </View>
                    <Table size="small" variation="bordered" highlightOnHover>
                      <TableHead>
                        <TableRow>
                          <TableCell width="1px" />
                          {check.reportColumns.map((reportColumn) => (
                            <TableCell key={reportColumn} as="th">
                              {reportColumn}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {check.campaigns.map((campaign) =>
                          mayBeFilteredByCampaign(check.id, campaign.campaignId, campaign.campaignName) ? (
                            <TableRow key={campaign.campaignId}>
                              <TableCell style={{ backgroundColor: 'white', cursor: 'unset' }}>{`${campaign.campaignId} - ${campaign.campaignName}`}</TableCell>
                              {campaign.reports.map((report) => (
                                <TableCell key={report.name} onClick={() => report?.id && !report.isNotChecked() && openModal({ campaign, report })}>
                                  <TableCellContent>{getReportStatusIcon(report)}</TableCellContent>
                                </TableCell>
                              ))}
                            </TableRow>
                          ) : null
                        )}
                      </TableBody>
                    </Table>
                  </View>
                ) : (
                  getEmptyCheckItemsJsx(isLoadingCheckItems)
                )}
              </ExpanderItem>
            );
          })}
        </Expander>
      )}
    </Container>
  );
}
