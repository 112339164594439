import styled from "styled-components";
import {View} from "@aws-amplify/ui-react";

// eslint-disable-next-line import/prefer-default-export
export const TableContainer = styled(View)`
  display: flex;
  overflow-x: scroll;
  width: 98%;
  margin: auto;
`;
