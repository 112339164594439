import { Button } from '@aws-amplify/ui-react';
import React, { useCallback, useEffect } from 'react';

import { Backdrop, Modal, ModalContent, ModalHeader, ModalHeaderText } from './styles';

export default function ModalComponent({
  isOpen = false,
  size = 'sm',
  header,
  onClose,
  children
}: {
  isOpen: boolean;
  size: 'sm' | 'md' | 'lg';
  header: string;
  onClose: () => void;
  children: React.ReactNode;
}) {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', onKeyDown);
    } else {
      document.removeEventListener('keydown', onKeyDown);
    }

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [isOpen, onKeyDown]);

  if (!isOpen) {
    return null;
  }
  return (
    <>
      <Backdrop isOpen={isOpen} onClick={onClose} />
      <Modal size={size}>
        <ModalHeader>
          <ModalHeaderText>{header}</ModalHeaderText>
          <Button variation="link" onClick={onClose} color="black" backgroundColor="white">
            X
          </Button>
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
      </Modal>
    </>
  );
}
