import { TextField } from '@aws-amplify/ui-react';
import { FormEvent } from 'react';
import Autosuggest, { SuggestionsFetchRequestedParams } from 'react-autosuggest';

import './styles.css';

type IInputProps = {
  value: string;
  onChange: (event: FormEvent<HTMLElement>) => void;
  placeholder?: string;
};

type IProps = IInputProps & {
  onFetch: (value: string) => void;
  clearSuggestions: () => void;
  onSelect: (suggestion: { label: string; value: any }) => void;
  suggestions: {
    label: string;
    value: any;
  }[];
};

// eslint-disable-next-line react/jsx-props-no-spreading
const renderInputComponent = (inputProps: any) => <TextField {...inputProps} type="text" label="" />;

export default function Typeahead({ value, onChange, placeholder, suggestions, onFetch, clearSuggestions, onSelect }: IProps) {
  const onSuggestionsFetchRequested = ({ value: input, reason }: SuggestionsFetchRequestedParams) => {
    if (reason === 'suggestion-selected') {
      return;
    }
    onFetch(input);
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      inputProps={{
        value,
        placeholder,
        onChange
      }}
      // @ts-ignore
      renderInputComponent={renderInputComponent}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={clearSuggestions}
      renderSuggestion={(suggestion) => <span>{suggestion.label}</span>}
      getSuggestionValue={(suggestion) => suggestion.value}
      onSuggestionSelected={(event, { suggestion }) => onSelect(suggestion)}
    />
  );
}
