export default function isAmplifyEnvWithSSO() {
  /*
    In order to determine if this is a main Amplify branch in any of the AWS Accounts,
    we look up the REACT_APP_* envs which are only being set by Terraform on the
    main branches.
  */
  const requiredEnvs = [
    process.env.REACT_APP_AWS_ENV_NAME,
    process.env.REACT_APP_AWS_REGION,
    process.env.REACT_APP_USER_POOL_ID,
    process.env.REACT_APP_USER_WEBCLIENT_ID,
    process.env.REACT_APP_SSO_REDIRECT_URL
  ];

  return requiredEnvs.map((s) => s && s.length > 0).every(Boolean);
}
